{
  "key": "value",
  "ABOUT": "Om",
  "THESSA": "Thessa",
  "THESSA_IS_THE_DOCTORAL_STUDIES": "Thessa är Helsingfors universitets planerings- och rapporteringsverktyg för doktorandstudier.",
  "ALL_KINDS_OF_FEEDBACK_AND_ERRO": "Alla typer av respons och felrapporter är mycket uppskattade.",
  "THESSA_ADMIN_HELSINKI_FI": "thessa-admin@helsinki.fi",
  "RIGHTS_OBLIGATIONS_AND_RESPON": "Rättigheter, förpliktelser och skyldigheter i forskarutbildningen vid Helsingfors universitet",
  "PROBLEMS_LOGGING_IN": "Problem med att logga in?",
  "IF_YOU_FORGOT_YOUR_PASSWORD_Y": "Om du har glömt ditt lösenord kan du",
  "RESET_IT": "återställ det",
  "SEND_PASSWORD_RESET_LINK_TO_MY": "Skicka länk för återställande av lösenord till min e-post",
  "EMAIL_FOR_THESSA_ACCOUNT": "E-post för Thessa-konto",
  "SEND": "Skicka",
  "BACK_TO_LOGIN": "Tillbaka till login",
  "BACK_TO_MY_PHD_PROJECT": "Tillbaka till Mitt doktorandprojekt",
  "MESSAGE_SENT_CHECK_YOUR_EMAIL": "Meddelande skickat. Kontrollera din e-postinkorg och öppna länken i e-postmeddelandet om lösenordsåterställning.",
  "TOO_MANY_PASSWORD_RESET_REQUES": "För många förfrågningar om lösenordsåterställning.",
  "WAIT_FIVE_MINUTES_BEFORE_TRYIN": "Vänta fem minuter innan du försöker igen. Om problemet kvarstår, <0>kontakta oss</0> för mer hjälp.",
  "HELPDESK": "kontakta IT-Helpdesk.",
  "YOU_MAY_RESET_YOUR_PASSWORD": "du kan återställa ditt lösenord.",
  "SOMETHING_WENT_WRONG": "Något gick fel.",
  "TRY_AGAIN_LATER": "Försök igen senare. Om problemet kvarstår, <0>kontakta oss</0> för hjälp.",
  "RIGHTS_OBLIGATIONS_AND_RESPON1": "Rättigheter, förpliktelser och skyldigheter i forskarutbildningen vid Helsingfors universitet",
  "ABOUT_THESSA": "Om Thessa",
  "THE_DOCTORAL_STUDIES_PLANNING": "Planerings- och rapporteringsverktyget för doktorandstudier.",
  "EMAIL": "E-post",
  "PASSWORD": "Lösenord",
  "LOG_IN": "Logga in",
  "LOGIN_WAS_NOT_SUCCESSFUL": "Inloggningen lyckades inte.",
  "CHECK_USERNAME_AND_PASSWORD_I": "Kontrollera användarnamn och lösenord. Om det inte hjälper, får du mer hjälp från",
  "UNIVERSITY_OF_HELSINKI_LOGIN": "Har du Helsingfors universitets användarnamn?",
  "USE_UNIVERSITY_OF_HELSINKI_LOGIN": "Använd inloggning för Helsingfors universitet",
  "UNIVERSITY_OF_HELSINKI_USERNAM": "Helsingfors universitets användarnamn",
  "CHECK_USERNAME_AND_PASSWORD_I1": "Kontrollera användarnamn och lösenord. Om det inte hjälper,",
  "TOO_MANY_FAILED_LOGIN_ATTEMPTS": "För många misslyckade inloggningsförsök.",
  "WAIT_FIVE_MINUTES_BEFORE_TRYIN1": "Vänta fem minuter innan du försöker igen. Kontrollera användarnamn och lösenord. Om det inte hjälper, får du mer hjälp från",
  "YOU_NEED_AN_INVITATION_OR_AN_A": "Du behöver en inbjudan eller en aktiv studierätt för doktorsexamen för att få tillgång till Thessa.",
  "YOUR_EMAIL_ADDRESS_IS_ALREADY": "Din e-postadress används redan av någon i Thessa.",
  "CONTACT_US": "Kontakta oss",
  "CONTACT_US_END_OF_SENTENCE": "kontakta oss.",
  "FOR_HELP_WITH_THIS_ERROR": "för hjälp med detta fel.",
  "LOGIN_FAILED_WITH_ERROR_CODE": "Inloggning misslyckades med felkod",
  "NON_UNIVERSITY_LOGIN_EXTERNAL": "Är du extern användare?",
  "USE_NON_UNIVERSITY_LOGIN": "Använd inloggning utanför universitetet",
  "NON_UNIVERSITY_LOGIN": "Inloggning utanför universitete för extern användare",
  "USE_YOUR_UNIVERSITY_OF_HELSINK": "Använd ditt användarnamn och lösenord till Helsingfors universitets informationssystem för att logga in.",
  "FORGOT_PASSWORD": "Har du glömt ditt lösenord?",
  "RESET_PASSWORD": "Återställ lösenord",
  "NEW_PASSWORD": "Nytt lösenord",
  "SAVE_PASSWORD": "Spara lösenord",
  "CANCEL": "Avbryt",
  "PASSWORD_SET_REDIRECTING_YOU": "Lösenord inställt. Omdirigerar dig",
  "INSIDE": "inne",
  "THERE_WERE_PROBLEMS_WITH_SAVIN": "Det uppstod problem med att spara lösenordet:",
  "SIGN_UP_TO_THESSA": "Registrera dig i Thessa",
  "IF_YOU_HAVE_A_UNIVERSITY_OF_HE": "Om du har ett konto vid Helsingfors universitet, ska du använda dess användarnamn och lösenord i inloggningsformuläret ovan. Alla studenter och anställda har ett konto.",
  "MON": "Mån",
  "TUE": "Tis",
  "WED": "Ons",
  "THU": "Tors",
  "FRI": "Fre",
  "SAT": "Lör",
  "SUN": "Sön",
  "LOADING_WITH_ELLIPSIS": "Laddar…",
  "YOU_MAY_USE": "Du kan använda",
  "MARKDOWN": "Markdown",
  "ITALICS": "*kursiv*",
  "BOLD": "**fet stil**",
  "LINKS_HTTPS": "[länkar](https://...)",
  "HEADINGS": "# rubriker",
  "LISTS": "- listor",
  "AND_MORE": "och mer.",
  "THESSA_FOR": "Thessa för",
  "UNIVERSITY_OF_HELSINKI": "Helsingfors universitet",
  "PRIVACY_POLICY": "Integritetspolicy",
  "ASK_FOR_HELP_AND_GIVE_FEEDBACK": "Be om hjälp och ge feedback",
  "TOGGLE_NAVIGATION": "Växla navigering",
  "LOG_OUT": "Logga ut",
  "LOGIN": "Logga in",
  "REMINDERS1": "Påminnelser",
  "PROFILE1": "Profil",
  "HELP1": "Hjälp",
  "MY_PHD_PROJECT": "Mitt doktorandprojekt",
  "MY_DOCTORAL_CANDIDATES": "Mina doktorander",
  "DOCTORAL_CANDIDATES1": "Doktorander",
  "USERS1": "Användare",
  "QUEUE1": "Kö",
  "STATISTICS1": "Statistik",
  "LEFT": "vänster",
  "BOTTOM": "botten",
  "ARE_YOU_SURE": "Är du säker?",
  "CONFIRM_DELETE": "Ja, ta bort",
  "THANKS": "Tack!",
  "YOU_CAN_GIVE_MORE_FEEDBACK_HER": "Du kan ge mer respons här:",
  "SEND_FEEDBACK": "Skicka respons",
  "PAGE_HEADER": "Sidhuvud",
  "HEADING": "Rubrik 1",
  "HEADING1": "Rubrik 2",
  "HEADING2": "Rubrik 3",
  "HEADING3": "Rubrik 4",
  "HEADING4": "Rubrik 5",
  "HEADING5": "Rubrik 6",
  "LEAD_TEXT": "Ingress",
  "BODY_TEXT": "Brödtext",
  "SMALL_TEXT": "Liten text",
  "LABEL": "Etikett 1",
  "LABEL1": "Etikett 2",
  "LABEL2": "Etikett 3",
  "CHECK": "Kontroll 1",
  "CHECK1": "Kontroll 2",
  "SUBMIT": "Skicka in",
  "SAVE": "Spara",
  "TOGGLE": "Växla",
  "CALENDAR": "kalender",
  "OK": "ok",
  "REMOVE": "ta bort",
  "SUBMITTED": "Lämnad",
  "CONCLUSION_MISSING": "Avslutning saknas",
  "GIVE_FEEDBACK": "Ge respons",
  "DEFAULT": "Standard",
  "RIGHT": "Höger",
  "LEFT1": "Vänster",
  "CALL_TO_ACTION": "Uppmaning till handling",
  "SECONDARY": "Sekundär",
  "ACTION": "Handling",
  "ACTIONS": "Åtgärder",
  "STUDENT_NUMBER": "Student nummer",
  "NAME": "Namn",
  "ACCOUNT": "Konto",
  "NO_ACCOUNT": "Inget konto",
  "NO_SUPERVISORS_IN_OODI": "Inga handledare i Oodi",
  "STUDY_RIGHT_START_DATE": "Studierättens rätta startdatum",
  "RECENT": "NYLIGEN",
  "COORDINATOR_QUEUE": "Koordinatorkö",
  "LOADING_INACTIVE_USERS": "Laddar inaktiva användare...",
  "THERE_WAS_AN_ERROR_IN_LOADING": "Det uppstod ett fel när inaktiva användare laddades.",
  "PLEASE": "Vänligen",
  "TRY_AGAIN": "försök igen",
  "LET_US_KNOW": "låt oss få veta",
  "IF_THE_PROBLEM_DOES_NOT_GO_AWA": "om problemet inte försvinner.",
  "INACTIVE_USER": "Inaktiv användare",
  "TYPE": "Typ",
  "N_A": "Inte i bruk",
  "NO_COURSES_IN_PLAN_YET": "Inga kurser finns ännu i planen.",
  "ADD_ONE_BY_CLICKING_THE": "Lägg till en kurs genom att klicka på",
  "ADD": "Lägg till",
  "BUTTON_ABOVE": "knappen ovan!",
  "TITLE": "Titel",
  "CREDITS": "Studiepoäng",
  "STATUS": "Status",
  "COMPLETED": "Avslutad",
  "NOT_COMPLETED": "Ej genomförd",
  "COMPLETED_AT": "Avslutad kl",
  "TO_BE_COMPLETED_AT": "Ska slutföras kl",
  "NOT_AVAILABLE": "Inte tillgänglig",
  "CHANGED_SINCE_LAST_OPENED": "Ändrad sedan senaste öppnade",
  "REPORT": "Rapportera",
  "SUPERVISION_PLAN": "Handledningsplan",
  "SUPERVISION_PLAN_other": "Handledningsplaner",
  "MY_DOCTORAL_CANDIDATES1": "Mina doktorander",
  "LOADING_DOCTORAL_CANDIDATES": "Laddar doktorander...",
  "FAILED_TO_LOAD_DOCTORAL_CANDID": "Det gick inte att ladda doktorander. Du kan prova att ladda om sidan. Om felet kvarstår, kontakta Thessa-användarstödet.",
  "MISSING_DOCTORAL_CANDIDATES": "Fattas doktorander?",
  "TO_SEE_YOUR_DOCTORAL_CANDIDATE": "För att se dina doktorander i Thessa, se till att de registrerar sig i Thessa och bjuder in dig att bli handledare eller medlem av uppföljningsgruppen.",
  "YOU_WILL_GET_THE_INVITATION_BY": "Du får inbjudan via e-post. Acceptera det för att få tillgång till dina doktorander.",
  "HIDE": "Dölj",
  "SHOW": "Visa",
  "CLOSED_ACCOUNTS": "stängda konton",
  "RELATIONSHIP": "Relation",
  "PROGRESS": "Framsteg",
  "YOU_HAVE_NO_DOCTORAL_CANDIDATE": "Du har inga doktorander i Thessa.",
  "NO_DOCTORAL_CANDIDATES": "Inga doktorander.",
  "YOUR_USER_ACCOUNT_NEEDS_TO_BE": "Ditt användarkonto måste aktiveras innan du kan skapa din första rapport. Innan ditt konto kan aktiveras måste du",
  "INVITE_ALL_YOUR_SUPERVISORS_AN": "bjud in alla dina handledare och medlemmar av uppföljningsgruppen",
  "IN_THESSA": "i Thessa.",
  "YOU_WILL_RECEIVE_AN_EMAIL_WHEN": "Du kommer att få ett e-postmeddelande när ditt konto har aktiverats.",
  "INVITE_SUPERVISORS": "Bjud in handledare",
  "THERE_ARE_NO_SUPERVISION_PLANS": "Det finns inga handledningsplaner tills vidare.",
  "DOCTORAL_CANDIDATE_AND_SUPERVI": "Doktoranden och handledaren kommer överens om att följa en handledningsplan som ska definiera samarbetet mellan doktoranden och handledare. Planen skapas på handledarens konto och undertecknas sedan av doktoranden och handledaren.",
  "SUPERVISORS": "Handledare",
  "LOADING_NEXT_REPORT_DUE_DATE": "Laddar nästa rapportförfallodatum...",
  "NEXT_REPORT_DUE_ON": "Nästa rapport kommer",
  "NEXT_REPORT_RECOMMENDED_TO_BE_SENT_AT": "En rapport ska lämnas in cirka en vecka före uppföljningsgruppens möte.",
  "PERIOD": "Period",
  "PUBLICATIONS": "Publikationer",
  "COURSES": "Kurser",
  "GRADUATION": "Utexaminering",
  "CONCLUSION": "Avslutning",
  "DRAFT": "Utkast",
  "OPEN": "Öppna",
  "MISSING": "Saknas",
  "HAVE_A_THESIS_COMMITTEE_MEETIN": "Håll ett möte med uppföljningsgruppen och skriv avslutningen genom att öppna rapporten.",
  "REPORTS": "Rapporter",
  "LOADING_REPORTS": "Laddar rapporter...",
  "NO_REPORTS_YET": "Inga rapporter ännu",
  "VIEW_CUMULATIVE_REPORT": "Visa kumulativ rapport",
  "THESSA_IS_IN_MAINTENANCE": "Thessa är under underhåll",
  "PLEASE_WAIT_A_FEW_MINUTES_AND": "Vänta några minuter och <0>ladda om sidan</0>",
  "RELOAD_THE_PAGE": "Ladda om sidan",
  "PAGE_NOT_FOUND": "Sidan hittas inte",
  "SORRY_THE_PAGE_YOU_OPENED_CAN": "Tyvärr, sidan du öppnade kan inte hittas. Prova",
  "FRONT_PAGE_INSTEAD": "framsidan istället",
  "ADD_GRANT": "Lägg till stipendium",
  "ADD_SALARY": "Lägg till lön",
  "SALARIES": "Löner",
  "GRANTS": "Stipendier",
  "NO_SALARIES_IN_PLAN_YET": "Inga löner finns ännu i planen.",
  "NO_SALARIES": "Inga löner.",
  "NO_GRANTS_IN_PLAN_YET": "Inga stipendier finns ännu i planen.",
  "NO_GRANTS": "Inga stipendier.",
  "SOURCE": "Källa",
  "AMOUNT": "Belopp",
  "FOR_MONTHS": "I månader",
  "USED_FOR": "Används för",
  "NOT_GRANTED": "Inte beviljat",
  "GRANTED": "Beviljas",
  "FOR_DATES": "För datumen",
  "HELP_MORE_GUIDES_LINK_PREFIX": "Öppna",
  "HELP_MORE_GUIDES_LINK_URL": "https://studies.helsinki.fi/instruktioner/artikel/thessa",
  "HELP_MORE_GUIDES_LINK_LINK_TEXT": "Instruktioner för studerande",
  "HELP_MORE_GUIDES_LINK_SUFFIX": "för mer instruktioner om hur du använder Thessa.",
  "THE_THREE_MAIN_PARTS_OF_THESSA": "De tre huvuddelarna av Thessa",
  "THESSA_CONSISTS_OF_THREE_MAIN": "Thessa består av tre huvuddelar:",
  "PHD_PROJECT1": "Mitt doktorandprojekt",
  "SUPERVISION_PLAN_IS_A_SET_OF_D": "Handledningsplanen är en uppsättning beslut som fattas tillsammans mellan doktoranden och handledarna för att se till att arbetet med doktorsavhandlingen fortskrider smidigt. Planen skapas av handledaren på hens Thessa-konto, varefter doktoranden och de  övriga handledarna skriver under den.",
  "PHD_PROJECT_IS_A_PLACE_FOR_DOC": "Mitt doktorandprojekt är en plats där doktorander att planera projektekts olika delar och anteckna framsteg. Delarna omfattar till exempel publikationer, kurser och finansiering. Alla denna information och mer kan anteckans i Thessa för kommunikation med handledare.",
  "ANOTHER_GREAT_BENEFIT_OF_KEEPI": "En annan stor fördel med att anteckna framstegen är att det gör det lättare att skriva rapporter i och med att det mesta av innehållet automatiskt kan kopieras från doktorandprojektet till rapporten.",
  "DOCTORAL_CANDIDATES_AND_SUPERV": "Doktorander och handledare",
  "DOCTORAL_CANDIDATES_ARE_THE_ON": "Doktoranden bjuder själv in sina handledare till Thessa via Profile-fliken. Thessa skickar inbjudan per e-post till adressen som doktoranden angivit. Inbjudan innehåller en länk för att registrera sig som doktorandens handledare I Thessa. Länken är i kraft i två veckor. Kopplingen mellan handledaren och en doktoranden är således på doktorandens ansvar och handledare kan inte välja sina doktorander i Thessa. På så sätt skyddar Thessa från obehörig åtkomst till doktorandens uppgifter. Doktoranderna fungerar således själv som portvakt för sittt Thessa-konto.",
  "THE_DOCTORAL_CANDIDATE_CAN_STA": "Doktoranden kan börja arbeta med Thessa direkt efter att ha loggat in. Handledare och medlemmar av uppföljningsgruppen (se nedan) måste dock bjudas in innan rapporteringscykeln kan slutföras.",
  "TO_LATER_CHANGE_DOCTORAL_CANDI": "För att senare byta handledare ska doktoranden besöka sin profilsida.",
  "THESIS_COMMITTEES_TC": "Uppföljningsgrupper",
  "THESIS_COMMITTEES_ARE_REQUIRED": "Uppgföljningsgrupper krävs i alla doktorandprogram från och med 2020. Många doktorandprogram har redan infört praxisen.",
  "THESSA_SUPPORTS_INVITING_MEMBE": "Uppföljningsgruppens medlemmar bjuds in på ett liknande sätt som handledaren. Gruppens medlemmar kan granska och kommentera doktorandens rapport i Thessa.",
  "THE_CONCLUSIONS_AND_RECOMMEND": "Slutsatser och rekommendationer' skrivs av doktoranden under uppföljningsgruppens möte då alla medlemmar är närvarande.",
  "THESSA_ASKS_DOCTORAL_CANDIDATE": "Thessa påminner doktoranderna om att fylla i rapporten över doktorandprojekts framsteg minst en gång per år under doktorandstudiernas gång. Rapporten är en översikt över det doktorandprojektets dåvarande läge och däför fås största delen av informationen direkt från Mitt doktorandprojekt. Rapporten kräver dessutom en del tillägssinformation som doktoranden själv ska skriva.",
  "AFTER_THE_DOCTORAL_CANDIDATE_H": "Efter att doktoranden har lämnat in rapporten kan  handledare och uppföljningsgruppems medlemmar att meddelas genast granska den. I samband med rapporten finns ett diskussionsfält där alla parter kan kommentera rapportens innehåll och föreslå ändingar eller förbättringar. Thessa skickar kommentarerna också till doktorandens e-post och kan vid behov göra ändringar i rapporten.",
  "THERE_WILL_BE_REMINDER_EMAILS": "Thessa skickar ut påminnelser via e-post om deadlines för rapporteringen. Rapportens deadline är alltid det senare datumet av följande datum:",
  "ONE_YEAR_FROM_THE_LAST_REPORT": "Ett år från den senaste rapporten.",
  "ONE_YEAR_FROM_THE_BEGINNING_OF": "Ett år från början av doktorandstudierna.",
  "CLOSING_THE_REPORT": "Avslutar rapporten",
  "AT_THE_END_OF_REPORTING_CYCLE": "I slutet av rapporteringscykeln när alla begärda ändringar har gjorts i rapporten, avslutar doktoranden rapporten genom att skriva 'slutsatser och rekommendationer'. Detta avsnitt skrivs vanligtvis av doktoranden under uppföljningsgruppens möte när alla medlemma är närvarande.",
  "IN_SOME_DOCTORAL_PROGRAMS_THE": "Inom vissa doktorandprogram förutsätts uppföljningsgrupperna inte ännu och rapporten granskas endast av handledarna. I sådana fall avslutar doktoranden rapporten genom att skriva avslutningen  (Conclusions and recommendations). Vanligtvis skrivs avslutningen i slutet av uppföljningsgruppens möte tillsammans med gruppens medlemmar.",
  "FEEDBACK_AND_ERROR_REPORTS": "Respons och supportbegäran",
  "CONTACT_US1": "kontakta oss",
  "TRY_TO_INCLUDE_AS_MUCH_DETAI": "Berätta om problemet eller felet så noggrant som möjligt. Thessa utvecklas fortgående och all resons är värdefull för oss.",
  "IF_YOU_NEED_MORE_HELP_PLEASE": "Om du har frågor angående Thessa eller behöver mer hjälp",
  "INVITE": "Inbjudan",
  "S_EMAIL_ADDRESS": "{{roleName}}s e-postadress",
  "INVITE_TO_YOUR_PHD_PROJECT_IN": "Bjud in till ditt doktorandprojekt i Thessa",
  "INVITATION_SUBMITTED": "Inbjudan skickad",
  "SUBMITTING_INVITATION_FAILED": "Det gick inte att skicka inbjudan",
  "YOU_ARE_INVITED": "Du är inbjuden",
  "WHAT_IS_THESSA": "Vad är Thessa?",
  "ALL_DOCTORAL_CANDIDATES_AND_TH": "Alla doktorander och deras handledare och medlemmar i uppföljningsgruppen kan använda det.",
  "WELCOME_TO_THESSA": "Välkommen till Thessa!",
  "YOU_ARE_NOT_CONNECTED_TO_ANYON": "Du är inte kopplad till någon.",
  "ASK_YOUR_DOCTORAL_CANDIDATES_T": "Be dina doktorander att skicka en inbjudan från dera Thessa-konto.",
  "OTHERWISE_IT_S_GOING_TO": "Annars blir det ensamt här.",
  "NO_NOTES": "Inga anteckningar.",
  "EDIT": "Redigera",
  "SAVING": "Sparar...",
  "UPDATE_ANNUAL_REPORTS": "Uppdatering: Årsrapport",
  "ANNUAL_REPORTS_SECTION_HAS_BEE": "Årsrapportsektionen har uppdaterats för att tydligare visa rapportens status och begärda åtgärder.",
  "UPDATE_CUMULATIVE_REPORT": "Uppdatering: Kumulativ rapport",
  "CUMULATIVE_REPORT_VIEW_SHOWS_A": "Den kumulativa rapportvyn visar alla dina rapporter samtidigt vilket gör det lättare att få en helhetsbild. Tryck på knappen 'Visa kumulativ rapport' i avsnittet för årsrapporter för att öppna den.",
  "UPDATE_REVIEWING_REPORTS": "Uppdatering: Granska rapporter",
  "CUMULATIVE_REPORT": "Kumulativ rapport",
  "YOU_CAN_NOW_SEE_THE_CUMULATIVE": "Du kan nu se den kumulativa rapporten när du granskar.",
  "COMMENTING_REPORT": "Kommenterande rapport",
  "DISCUSS_REPORTS": "Diskutera rapporter.",
  "NEW_IMPROVEMENTS": "Nya förbättringar!",
  "INVITE_SUPERVISORS_AND_THESIS": "Bjud in handledare och uppföljningsgruppens medlemmar med hjälp av e-postadresser.",
  "DISCUSS_IN_SIMPLIFIED_ANNUAL_R": "Diskutera i förenklad årsredovisningsöversikt.",
  "REPORTS_ARE_CLOSED_BY_WRITING": "Rapporten avslutas genom att skriva 'slutsatser och rekommendationer'.",
  "READ_MORE_FROM_HELP_PAGE": "Läs mer från hjälpsidan.",
  "SET_OF_DECISIONS_BETWEEN_DOCTO": "En pppsättning beslut mellan doktorand och handledare för att säkerställa att doktorandprojektet fortlöper smidigt.",
  "KEEPING_TRACK_OF_FUTURE_PLANS": "Uppföljning av framtida planer för avancemang av studier, publikationer och annat relaterat till doktosexamen.",
  "A_SNAPSHOT_OF_PHD_PROJECT_IT": "En ögonblicksbild av doktorandprojektet. Den skapas en gång per år för att underlätta kommunikationen mellan doktorand och handledare.",
  "START_USING_THESSA": "Börja använda Thessa!",
  "WE_RECOMMEND_THAT_YOU_START_US": "Vi rekommenderar att du börjar använda Thessa genom att skapa en handledningsplan. Kontakta din handledare för att skapa den.",
  "THEN_YOU_SHOULD_UPDATE_YOUR_PH": "Då bör du uppdatera dina doktorandprojektplaner i Thessa så att de blir tydligt synliga för dig och din handledare. Du kan börja med det här genast!",
  "YOU_CAN_FIND_MORE_DETAILS_ABOU": "Du hittar mer information om Thessa från hjälpsektionen i huvudmenyn.",
  "IF_YOU_FIND_BUGS_OR_ERRORS_IN": "Om du hittar buggar eller fel i Thessa, eller har en idé om hur du kan göra det bättre,",
  "DROP_AN_EMAIL_TO_US": "skicka oss ett e-posmeddelande",
  "YOUR_DOCTORAL_CANDIDATES_WILL": "Dina doktorander kommer snart att länka sina Thessa-konton till dina. Därefter rekommenderar vi att du börjar använda Thessa genom att skapa en handledningsplan för dina doktorander. Skapa den med ditt konto och sedan kommer alla att signera den via sitt Thessa-konto.",
  "REMOVE1": "Avlägsna",
  "CONFERENCES_AND_MOBILITY": "Konferens och mobilitet",
  "LOCATION": "PLATS",
  "DATES": "DATUM",
  "LOADING_CONFERENCES_AND_MOBILI": "Laddar konferenser och mobilitet...",
  "NO_CONFERENCES_OR_MOBILITY_PLA": "Inga konferenser eller mobilitet planerade.",
  "ADD_CONFERENCE_OR_MOBILITY": "Lägg till konferens eller mobilitet",
  "FAILED_TO_CREATE_CONFERENCE_OR_MOBILITY": "Det gick inte att skapa konferens eller mobilitet.",
  "DELETE": "Radera",
  "REALLY_CANCEL_INVITATION": "Vill du verkligen avbryta inbjudan?",
  "KEEP_INVITATION": "Behåll inbjudan",
  "CANCEL_INVITATION": "Avbryt inbjudan",
  "INVITATION_EXPIRED": "Inbjudan har löpt ut",
  "INVITED_NOT_YET_RESPONDED": "Inbjuden, väntar på svar",
  "MANUALLY_SEND_INVITE": "Thessa har ett tillfälligt problem med att skicka e-postmeddelanden utanför universitetet. Du bör även skicka engångslänken nedan till mottagaren av inbjudan själv.",
  "COPY_LINK": "Kopiera länken",
  "FIRST_NAME": "Förnamn",
  "LAST_NAME": "Efternamn",
  "EMAIL_ADDRESS": "E-postadress",
  "SAVED": "Sparad",
  "REALLY_REMOVE": "Vill du verkligen ta bort?",
  "THE_LAST_MEMBER_CANNOT_BE_REMO": "Den sista medlemmen kan inte tas bort.",
  "RESEND_INVITATION": "Skicka inbjudan igen",
  "INVITATION_TO_BECOME_A": "Inbjudan att bli en",
  "FOR": "för",
  "EXPIRES_ON": "Går ut den",
  "THIS_INVITATION_HAS_EXPIRED_Y": "Denna inbjudan har upphört att gälla. Du kan begära en ny inbjudan av doktorandanden",
  "ACCEPT": "Acceptera",
  "DECLINE": "Avslå",
  "DUE_TO_DATA_MIGRATION_THESSA": "På grund av datamigrering känner Thessa inte till startdatumet för denna status. Välj rätt datum nedan och spara publiceringen.",
  "NO_PUBLICATIONS_IN_PLAN_YET": "Inga publikationer i planen ännu.",
  "TOPIC_OR_TITLE": "Ämne eller titel",
  "EDIT_REMINDER": "Redigera påminnelse",
  "LOADING_YOUR_REMINDERS": "Laddar dina påminnelser",
  "NEW_REMINDER": "Ny påminnelse",
  "UPCOMING_REMINDERS": "Kommande påminnelser",
  "PAST_REMINDERS": "Tidigare påminnelser",
  "NO_REMINDERS": "Inga påminnelser",
  "DUE": "Till följd av",
  "SEND_REMINDERS_TO": "Skicka påminnelser till",
  "REMINDER_TIMING": "Tidpunkt för påminnelse",
  "DAYS_BEFORE_DUE_DATE": "dagar före förfallodatum",
  "CREATE_REPORT": "Skapa rapport",
  "YOUR_ACCOUNT_IS_INACTIVE_THER": "Ditt konto är inaktivt, därför kan du inte skapa en rapport ännu.",
  "YOUR_DOCTORAL_PROGRAMME_S_STAF": "Personalen på din doktorandprogram kommer att aktivera kontot åt dig när de har kontrollerat din profil.",
  "CANNOT_CREATE_REPORT_WHEN_A_PR": "Det går inte att skapa en rapport när en tidigare rapport är ett utkast. Skicka eller ta bort den innan du skapar en ny rapport.",
  "OPEN_REPORT": "Öppna rapporten",
  "CANNOT_CREATE_REPORT_WHEN_A_PR1": "Kan inte skapa en rapport eftersom en tidigare rapport inte har någon avslutning.",
  "SUMMARIZE_THE_REPORTING_PERIOD": "Sammanfatta rapporteringsperioden",
  "AS_AN_OVERVIEW_OF_THE_REPORTIN": "Lista tre positiva och tre negativa saker som en sammanfattning av rapporteringsperioden.",
  "BELOW_ARE_ALL_COURSES_YOU_COMP": "Nedan finns alla kurser du genomfört mellan",
  "YOU_CAN_EDIT_THE_LIST_ON": "Du kan redigera listan på",
  "PHD_PROJECT_PAGE": "Sida för doktorandprojekt",
  "NO_COURSES_IN_REPORT_RIGHT_NOW": "Inga kurser finns för tillfället i rapporten.",
  "BELOW_ARE_ALL_ACTIVE_SALARIES": "Nedan finns alla aktiva löner och stipendier som du har i din planen mellan",
  "YOU_CAN_EDIT_THE_LISTS_ON_TH": "Du kan redigera listorna på",
  "NO_SALARIES_IN_REPORT_RIGHT_NO": "Inga löner i rapporten just nu.",
  "NO_GRANTS_IN_REPORT_RIGHT_NOW": "Inga stipendier i rapporten just nu.",
  "BELOW_ARE_ALL_CONFERENCES_AND": "Nedan finns alla konferenser och mobilitet du genomfört mellan",
  "NO_CONFERENCES_OR_MOBILITY_IN": "Inga konferenser eller mobilitet finns i rapporten just nu.",
  "BELOW_ARE_ALL_PUBLICATIONS_THA": "Nedan finns alla publikationer som publicerats eller pågår mellan",
  "NO_PUBLICATIONS_IN_REPORT_RIGH": "Inga publikationer i rapporten just nu.",
  "BELOW_ARE_ALL_ACTIVE_TEACHINGS": "Nedan finns alla aktiv undervisning som du har i din plan mellan",
  "NO_COURSES_OR_WORKSHOPS_IN_REP": "Inga kurser eller workshops finns i rapportern just nu.",
  "NO_THESIS_INSTRUCTIONS_IN_REPO": "Inga avhandlingsinstruktioner i rapporten just nu.",
  "NOT_SUBMITTED": "Osänd",
  "CONCLUSION_DONE": "Avslutning gjord",
  "WRITE_CONCLUSION": "Skriv avslutning",
  "HAVE_A_THESIS_COMMITTEE_MEETIN1": "Håll ett möte med uppföljningsgruppen och skriv avslutningen till din rapport.",
  "THE_DOCTORAL_CANDIDATE_NEEDS_T": "Doktoranden ska hålla ett möte med uppföljningsgruppen och skriva avslutningen till rapporten.",
  "DONE": "Gjort",
  "REPORT_SUBMITTED_FOR_REVIEW_SU": "Rapporten har skickats för granskning.",
  "REPORT_SUBMITTED": "Rapport inlämnad",
  "REPORT_UPDATED": "Rapport uppdaterad",
  "EDIT_REPORT": "Redigera rapport",
  "YOU_CAN_MAKE_CHANGES_TO_THE_RE": "Du kan göra ändringar i rapporten.",
  "NO_REPORTS_TO_REVIEW_FOUND": "Inga rapporter att granska hittades.",
  "EDIT_REVIEW": "Redigera bedömning",
  "REVIEW_REPORT": "Granska rapporten",
  "CHANGES_SAVED": "Ändringar sparade",
  "LAST_SAVED": "Senast sparad",
  "CANCEL_EDITING": "Avbryt redigering",
  "SAVE_REPORT": "Spara rapport",
  "SUBMIT_REPORT_FOR_REVIEW": "Skicka in rapport för granskning",
  "CREATED_IN": "Skapad år",
  "CONFERENCES_AND_MOBILITY1": "Konferenser och mobilitet",
  "FUNDING": "Finansiering",
  "TEACHING": "Undervisning",
  "COURSES_AND_WORKSHOPS": "Kurser och workshops",
  "THESIS_INSTRUCTION": "Instruktion för examensarbetet",
  "YOU_ARE_EDITING_THE_REPORT_S_C": "Du redigerar rapportens avslutning och rekommendationer",
  "SHOW_EDITOR": "Visa redaktör",
  "PLEASE_INDICATE_WHO_ATTENDED_T": "Ange vänligen vem som deltog i uppföljningsgruppens möte.",
  "THESIS_COMMITTEE_MEETING_FORM": "Mötesblankett för uppföljningsgruppen",
  "CONCLUSION_AND_RECOMMENDATIONS": "Slutsats och rekommendationer",
  "NO_REPORT_FOUND_CANNOT_EDIT_CONCLUSION": "Rapporten hittades inte. Det går inte att redigera slutsatsen.",
  "NO_TEXT_TO_PREVIEW": "Ingen text att förhandsgranska",
  "CANNOT_SUBMIT_CONCLUSION_EMPTY": "Slutsatsen kan inte vara tom.",
  "THE_DOCTORAL_CANDIDATE_IS_EXPE": "Doktoranden förväntas fylla i detta formulär i slutet av uppföljningsgruppens möte när alla deltagare är närvarande.",
  "PREVIEW": "Förhandsvisning",
  "SUBMITTING_PLEASE_WAIT": "Skickar, vänta...",
  "CONCLUSION_CONFIRM_CANCEL": "Är du säker på att du vill sluta skriva slutsatsen? Det du har skrivit hittills kommer att gå förlorat.",
  "CONCLUSION_CANNOT_BE_EDITED": "Slutsats har redan skrivits för denna rapport och den kan inte ändras i efterhand. Du kan <0>gå tillbaka till rapporten</0>.",
  "CUMULATIVE_REPORT_FOR": "Kumulativ rapport för",
  "COMPLETED_IN_THE_MOST_RECENT": "slutfört i den senaste rapporten",
  "IN_PROGRESS_IN_THE_MOST_RECE": "framskridit i den senaste rapporten",
  "PUBLICATION_IS_IN_THESIS": "Publiceras i avhandlingen",
  "COMPLETE": "klar",
  "NOTHING_REPORTED_YET": "Inget rapporterat ännu.",
  "DELETE_REPORT": "Ta bort rapport",
  "YOU_CAN_DELETE_YOUR_REPORT_BEF": "Du kan radera din rapport innan den har skickats.",
  "COMPLETED1": "klar",
  "ESTIMATE_OF_HOW_COMPLETE_YOUR": "Uppskattning av hur fullständigt ditt doktorandprojekt är i procent.",
  "FULL_TIME": "heltid",
  "ESTIMATE_OF_FULL_PART_TIME_WOR": "Uppskattning av heltids-/deltidsarbete på doktorandprojekt under rapportperioden.",
  "YOUR_DOCTORAL_PROGRAMME_ASKS_Y": "Ditt doktorandprogram ber dig att skriva denna rapport för att få en uppfattning om hur ditt doktorandprojekt fortskrider.",
  "PLEASE_FILL_IN_ALL_OF_THE_FIEL": "Fyll vänligen i alla fält.",
  "THE_REPORT_WILL_BE_VISIBLE_TO": "Rapporten kommer att vara synlig för dina handledare, uppföljningsgruppmedlemmar och samordnare när den väl har lämnats in.",
  "THIS_REPORT_COVERS_DATES_BETWE": "Denna rapport täcker datum mellan",
  "AND": "och",
  "WHEN_YOU_ARE_DONE_PRESS_THE_S": "Tryck på Skicka-knappen när du är klar. Knappen blir synlig när du har fyllt i varje fält. Efter inlämning är rapporten öppen för diskussion.",
  "REMEMBER_TO_SAVE_THE_REPORT_PE": "Kom ihåg att spara rapporten med jämna mellanrum. Du kan när som helst spara rapporten och fortsätta skriva den senare.",
  "MESSAGE_REMOVED": "Meddelandet har tagits bort.",
  "DISCUSSION": "Diskussion",
  "LOADING": "Laddar upp...",
  "NO_DOCUMENT_FOUND_WITH_DATE": "Inget dokument hittades med datum",
  "APPROVED_BY_THE_BOARD_OF_DOCTO": "Godkänd av ledingsruppen för doktorsutbildning vid Helsingfors universitet, <0>{{date}}</0>.",
  "THESSA_JOIN_A_THESIS_COMMITT": "Thessa - Gå med i en uppföljningsgrupp",
  "WITH_YOUR_THESSA_CREDENTIALS_O": "med ditt Thessa-användar-ID eller använd ditt användar-ID för Helsingfors universitet.",
  "OTHERWISE": "Annars",
  "REGISTER_AN_EXTERNAL_ACCOUNT": "registrera ett externt konto",
  "YOU_WILL_USE_THIS_FOR_LOGGING": "Du kommer att använda detta för att logga in.",
  "AT_LEAST_CHARACTERS": "Minst 8 tecken.",
  "YOUR_ORGANIZATION": "Din organisation",
  "FOR_EXAMPLE_FINNISH_METEOROLO": "Till exempel: Meteorologiska institutet eller Acme Inc.",
  "THERE_WERE_PROBLEMS_WITH_SIGN": "Det uppstod problem med registreringen:",
  "WELCOME_TO_THESSA_AS_A_NON_UN": "Välkommen till Thessa! Som användare utanför Helsingfors universitet kan du skapa ditt konto här.",
  "ERROR": "Fel",
  "SORRY_AN_ERROR_HAS_OCCURRED_I": "Ett fel har uppstått vid registreringen. Försök igen genom att uppdatera sidan, eller",
  "DOCTORAL_STUDIES": "Doktorandstudier",
  "FILL_IN_DETAILS_OF_YOUR_STUDIE": "Fyll i detaljer om dina studier.",
  "BEGINNING_OF_DOCTORAL_STUDIES": "Början av doktorandstudier",
  "DOCTORAL_PROGRAMME": "Doktorandprogram",
  "BACK": "Tillbaka",
  "CONTINUE": "Fortsätt",
  "OR": "eller",
  "LOG_OUT1": "logga ut",
  "WELCOME_TO_THESSA1": "Välkommen till Thessa",
  "WE_NEED_SOME_INFORMATION_ABOUT": "Vi behöver lite information om dig innan du kan fortsätta.",
  "MY_ROLE_IN_THESSA": "Min roll i Thessa",
  "DOCTORAL_CANDIDATE": "Doktorand",
  "SUPERVISOR": "Handledare",
  "SUPERVISOR_other": "Handledare",
  "THESIS_COMMITTEE_MEMBER": "Uppföljningsgruppmedlem",
  "VERIFY_YOUR_DETAILS": "Verifiera dina uppgifter",
  "WE_FOUND_THE_FOLLOWING_DETAILS": "Vi hittade följande information om dig automatiskt.",
  "PLEASE_CHECK_THAT_THEY_ARE_COR": "Kontrollera att de är korrekta och korrigera vid behov.",
  "LIST_DOCTORAL_CANDIDATES_BY_RE": "Lista doktorander efter rapportstatus",
  "LIST_REVIEWERS_OF_NOT_COMPLETE": "Lista granskare av ej färdigställda rapporter",
  "DOWNLOAD_THESSA_ADMIN_REPORT": "Ladda ner Thessa admin rapport",
  "REPORT_CONCLUSIONS_OVERVIEW": "Rapport översikt över slutsatser",
  "FILTER": "Filtrera",
  "SHOW_ALL_REPORTS": "Visa alla rapporter",
  "OVER_MONTH_OLD_REPORTS": "Över 2 månader gamla rapporter",
  "COPY_REVIEWER_EMAIL_ADDRESSES": "Kopiera granskarens e-postadresser",
  "EMAIL_ADDRESSES_COPIED": "E-postadresser kopierade",
  "CREATED_AT": "Skapad",
  "REVIEWERS": "Bedömare",
  "FILTER_BY_DOCTORAL_PROGRAMME": "Filtrera enligt doktorandprogram",
  "NO_REPORT_CONCLUSIONS_AVAILABL": "Inga slutsatser tillgängliga för doktorandprogrammet i",
  "PRELIMINARY_TITLE_OF_DISSERTAT": "Avhandlingens preliminära titel",
  "STUDENT_NUMBER1": "STUDENTNUMMER",
  "NAME1": "NAMN",
  "ACCOUNT_CLOSED": "Konto stängt:",
  "STUDIES": "STUDIER",
  "FACULTY": "FAKULTET",
  "SUPERVISORS1": "HANDLEDARE",
  "THESIS_COMMITTEE2": "UPPFÖLJNINGSGRUPPEN",
  "REPORT_CREATED": "Rapport skapad",
  "PROGRESS1": "framsteg",
  "LINK_TO_THE_DOCTORAL_CANDIDATE": "Länk till doktorandens Thessa-sida",
  "STUDENT_ID_NUMBER": "Student-ID-nummer",
  "ACCOUNT_STATUS_OPEN_CLOSED": "Kontostatus (öppet/stängt)",
  "IF_ACCOUNT_IS_CLOSED_REASON_F": "Anledning till stängning om kontot är stängt",
  "IS_ACCOUNT_ACTIVE_ACTIVE_INAC": "Är kontot aktivt (aktivt/inaktivt)",
  "EMAIL2": "e-post",
  "FACULTY1": "fakultet",
  "FACULTY_OF_DEGREE": "examensfakultet",
  "STARTING_DATE_OF_DOCTORAL_STUD": "Startdatum för doktorandstudier",
  "DOES_THE_DOCTORAL_CANDIDATE_HA": "Har doktoranden en handledningsplan",
  "IS_THE_SUPERVISION_PLAN_IN_EFF": "Är handledningsplanen i kraft",
  "SUPERVISORS2": "handledare",
  "SUPERVISORS_AND_THEIR_EMAIL_AD": "Handledare och deras e-postadresser",
  "IS_THE_ANNUAL_REPORT_SUBMITTED": "Är årsrapporten inlämnad",
  "THE_ANNUAL_REPORTING_IS_CLOSED": "Årsrapporten är avslutad och slutsatsen är skriven",
  "NEXT_REPORT_S_DEADLINE": "Deadline för nästa rapport",
  "THESIS_COMMITTEE_HAS_BEEN_ASSI": "Uppföljningsgruppen har tillsatts",
  "MEMBERS_OF_THE_THESIS_COMMITTE": "Medlemmarna i uppfölningsgruppen och deras e-postadresser",
  "OVERALL_PERCENTAGE_OF_COMPLETI": "Andel av examensarbetet färdig i procent",
  "ADVANCEMENT_OF_THESIS_WORK_COM": "Examensarbetets fortskridande under rapporteringsperioden",
  "FULL_TIME_PART_TIME": "Full tid del tid",
  "SALARIES2": "löner",
  "GRANTS2": "Stipdendier",
  "GRANTS_OBTAINED_AND_APPLIED_FO": "Stipendier erhållits och sökts",
  "EXPORT": "Exportera",
  "CREATE_SUPERVISION_PLAN": "Skapa tillsynsplan",
  "DELETE_SUPERVISION_PLAN": "Ta bort handledningplan",
  "ACCEPT_PLAN": "Acceptera planen",
  "VALID_BETWEEN": "Gäller mellan",
  "VALID_FROM": "Gäller tillsvidare från",
  "VALID_INDEFINITELY": "Handledningsplan är giltig tillsvidare",
  "DECISIONS": "Beslut",
  "SIGNATURES": "Signaturer",
  "SIGNED": "Signerad",
  "NOT_YET_SIGNED": "Inte ännu undertecknad",
  "WHAT_HAVE_YOU_DECIDED_ABOUT_TH": "Vänligen fyll i minst ett valfritt fält med ert beslut om hur ni kommer att arbeta tillsammans mot er doktorsexamen",
  "ACTIVE": "Aktiv",
  "PENDING": "Väntar",
  "EXPIRED": "Förldrar",
  "GENERAL_TERMS_ON": "Allmänna villkor på",
  "RIGHTS_OBLIGATIONS_AND_RESPON2": "Rättigheter, förpliktelser och skyldigheter i forskarutbildningen vid Helsingfors universitet",
  "THAT_APPLY_TO_THE_SUPERVISION": "som gäller för handledningsplanen.",
  "NO_COURSES_OR_WORKSHOPS_IN_PLA": "Inga kurser eller workshops finns ännu i planen.",
  "NO_THESIS_INSTRUCTIONS_IN_PLAN": "Inga instruktioner för avnahndlingen finns ännu i planen.",
  "ADD_TEACHING": "Lägg till undervisning",
  "TYPE_OF_TEACHING": "Typ av undervisning",
  "DATES1": "Datum",
  "COURSE_OR_WORKSHOP_NAME": "Namn på kursen eller workshopen",
  "MY_ROLE": "Min roll",
  "WORK_HOURS": "Arbetstimmar",
  "STUDENT_S_NAME": "Studentens namn",
  "THESIS_LEVEL": "Avhandlingens snivå",
  "THESIS_TOPIC_OR_TITLE": "Avhandlingens ämne eller titel",
  "SIGNED_UP_ON": "Konto skapat",
  "USERNAME": "Användarnamn:",
  "ACTIVATE_ACCOUNT_NOW": "Aktivera kontot nu",
  "USER_ACCOUNT_IS_NOT_YET_ACTIVA": "Användarkontot är inte ännu aktiverat",
  "CHECK_THE_ACCOUNT_DETAILS_IF": "Kontrollera kontoinformationen – om användaren faktiskt tillhör din organisation, aktivera kontot. Annars",
  "CONTACT_THESSA_S_ADMINS": "kontakta Thessas administratörer",
  "CLOSE_ACCOUNT": "Stäng Konto",
  "REASON_FOR_CLOSING_THE_ACCOUNT": "Anledning till stängande av kontot",
  "FACULTY2": "Fakultet:",
  "MAJOR": "Huvudämne:",
  "DOCTORAL_PROGRAMME1": "Doktorandprogram:",
  "BEGINNING_OF_DOCTORAL_STUDIES1": "Början på doktorandstudier:",
  "BACK_TO": "Tillbaka till",
  "ORGANIZATION": "Organisation:",
  "STUDENT_NUMBER2": "Studentnummer:",
  "VERIFIED_FROM_LDAP": "Verifierad från LDAP)",
  "NOT_VERIFIED_REPORTED_BY_DOCT": "Ej verifierad, rapporterad av doktorand)",
  "THESIS_COMMITTEE_MEMBER1": "Uppföljningsgruppmedlem",
  "IN_THESIS_COMMITTEE_FOR": "I uppföljningsgruppen för {{count}} doktorand",
  "IN_THESIS_COMMITTEE_FOR_other": "I uppföljningsgruppen för {{count}} doktorander",
  "THE_INVITATION_HAS_EXPIRED": "<0>Inbjudan har gått ut.</0> Be om en ny inbjudan från doktoranden.",
  "THERE_WAS_AN_ERROR_IN_LOADING_THE_INVITATION": "<0>Det uppstod ett fel när inbjudan laddades.</0> Vänligen <1>Försök igen</1>. Vänligen <2>meddela oss</2> om problemet inte försvinner.",
  "INVITATION_DECLINED": "<0>Inbjudan avvisades.</0> Tack för ditt svar!",
  "YOUR_ACCOUNT_IS_CLOSED": "Ditt konto är stängt",
  "SUPERVISION_PLAN_NOT_FOUND": "Handledningsplan hittades inte",
  "IN_THESIS": "I avhandlingen",
  "PUBLISHED": "Publicerad",
  "NOT_STARTED": "Ej påbörjad",
  "THE_SUPERVISION_PLAN_AFFECTS_THE_DOCTORAL": "Handledningsplanen berör doktoranden och doktorandens samtliga handledare.",
  "IT_IS_RECOMMENDED_THAT_SUPERVISION_PLANS": "Det rekommenderas att avhandlingsplanen skapas när alla berörda är närvarande. Om någon inte kan närvara, se till att skapa en plan som de också kan skriva under.",
  "CREATE_AND_SIGN_PLAN_AS": "Spara slutversionen och signera",
  "SAVE_DRAFT": "Spara utkast",
  "SAVE_DRAFT_FEEDBACK": "Utkastet sparat",
  "LOADING_DOCTORAL_PROGRAMMES": "Laddar doktorandprogram...",
  "FORM_IS_INCOMPLETE": "Formuläret är ofullständigt",
  "DESCRIPTION": "Beskrivning",
  "ROLE_IN_CONFERENCE": "Roll på konferens",
  "FAILED_TO_SAVE": "<0>Det gick inte att spara.</0> Försök igen eller <1>kontakta oss</1> om problemet kvarstår.",
  "DOCTORAL_SCHOOL": "Doktorandskola",
  "ROLE": "Roll",
  "PREVIOUS_PAGE": "Föregående <0>sida</0>",
  "NEXT_PAGE": "Nästa <0>sida</0>",
  "ROWS": "rader",
  "PAGE_OF": "<0>Sida {{currentPage}}</0> <1>av {{totalPages}}</1>",
  "PAGE": "Sida",
  "MAX_SIZE_PER_ATTACHMENT": "Max storlek per bilaga:",
  "UPLOAD_ATTACHMENTS": "Ladda upp bilagor",
  "UPLOADING": "Laddar upp...",
  "WHAT_PLANS_DO_YOU_HAVE": "Vilka planer har du? Stödjer dina avlagda studier detta mål?",
  "WRITE_HERE_ABOUT_YOUR_RESEARCH_PROGRESS": "Skriv här om dina forskningsframsteg under rapportperioden. Håll dig till huvudpunkterna och var kortfattad för att hjälpa avhandlingskommittén att enkelt utvärdera dina framsteg. Observera att du kan ladda upp din uppdaterade forskningsplan eller annat relevant material som en bilaga till denna rapport.",
  "FOR_EXAMPLE_WRITE_ABOUT_HOSTING_A_VISITOR": "Skriv till exempel om att ta emot en besökare eller organisera nationella studiebesök.",
  "FOR_EXAMPLE_HAVE_YOU_WRITTEN_A_BLOG": "Har du till exempel skrivit en blogg eller en populärvetenskaplig artikel, anordnat ett evenemang riktat till en publik utanför ditt eget område eller försökt påverka på något annat sätt?",
  "NO_REPORTS": "Inga rapporter",
  "ANY": "Några",
  "FILTER_BY": "Filtrera enligt {{target}}",
  "SORT_BY": "Sortera enligt",
  "THERE_WAS_AN_ERROR_IN_LOADING_DOCTORAL_CANDIDATES": "<0>Det uppstod ett fel med laddandet av doktorander.</0> Vänligen <1>Försök igen</1>. Vänligen <2>meddela oss</2> om problemet inte försvinner.",
  "THERE_ARE_NO_DOCTORAL_CANDIDATES_IN_THE_SELECTED": "Inga doktorander hittade.",
  "DOCTORAL_PROGRAMMES": "Doktorandprogram",
  "ERROR_LOADING_DOCTORAL_PROGRAMMES": "<0>Fel med laddabdet av doktorandprogram.</0> Vänligen <1>Försök igen</1>. <2>Meddela oss</2> om det inte hjälper.",
  "SELECT_NONE": "Välj inget",
  "SELECT_ALL": "Välj alla",
  "WHEN_WILL_EACH_STAGE_OF_THE_PUBLICATION_BEGIN": "När börjar varje skede av publiceringen? Uppskatta när skedet skulle börja om det är i framtiden. Varje status måste börja efter den föregående.",
  "THE_STATUSES_ARE_IN_INCORRECT_ORDER": "Statusarna är i felaktig ordning: varje status måste börja efter den föregående.",
  "TO_BE_INCLUDED_IN_THE_PHD_THESIS": "Ska ingå i doktorsavhandlingen",
  "SAVE_PUBLICATION": "Spara publikationen",
  "CREATE_ANOTHER": "Skapa en till",
  "CREATING_ANOTHER_PUBLICATION": "Skapar en till publikation",
  "I_HAVE_COMPLETED_THIS_COURSE": "Jag har avlagt den här kursen",
  "SAVE_COURSE": "Spara kursen",
  "CREATING_ANOTHER_COURSE": "Skapar en annan kurs",
  "CREATING_ANOTHER_GRANT": "Skapar ett till stipendium",
  "SOURCE_OF_GRANT": "Stipendiekälla",
  "FROM_WHICH_ORGANIZATION_IS_THE_GRANT_FROM": "Från vilken organisation kommer stipendiet?",
  "HOW_MUCH_IS_THIS_GRANT": "Hur stort är detta stipendiun?",
  "SAVE_GRANT": "Spara stipendium",
  "ENDS_AT": "Slutar",
  "BEGINS_AT": "Börjar",
  "WHAT_IS_THE_INTENDED_USAGE_OF_THIS_GRANT": "Vad är den avsedda användningen för detta stipendium?",
  "DURATION_OF_GRANT": "Stipendiets varaktighet",
  "WHEN_DOES_THIS_GRANT_BEGIN_AND_END": "När börjar och slutar detta stipendium? Gör en uppskattning om det inte finns något tydligt datum.",
  "CREATING_ANOTHER_TEACHING": "Skapa en till undervisning",
  "BEGINNING_DATE": "Startdatum",
  "END_DATE": "Slutdatum",
  "NAME_OF_THE_COURSE_OR_WORKSHOP": "Namn på kursen eller workshopen",
  "MY_ROLE_IN_TEACHING": "Min roll i undervisningen",
  "FOR_EXAMPLE_LECTURER_OR_ASSISTANT": "Till exempel <0>föreläsare</0> eller <1>assistent</1>.",
  "TOPIC_OR_TITLE_OF_THE_THESIS": "Avhandlingens ämne eller titel",
  "LEVEL_OF_THESIS": "Nivå på avhandlingen",
  "COURSE_OR_WORKSHOP": "Kurs eller workshop",
  "HOW_MANY_HOURS_OF_WORK_DID_YOU_SPEND_IN_TOTAL": "Hur många timmar arbete använde du totalt?",
  "NAME_OF_THE_STUDENT_WHO_YOUR_WERE_INSTRUCTING": "Namn på studenten som du hanledde",
  "MY_ROLE_IN_INSTRUCTING_THE_STUDENT": "Min roll i studentens handledning",
  "FOR_EXAMPLE_MAIN_SUPERVISOR_OR_CO_SUPERVISOR": "Till exempel <0>huvudhandledare</0> eller <1>andra handledare</1>.",
  "FOR_EXAMPLE_MASTERS_THESIS_OR_BACHELORS_THESIS": "Till exempel <0>magisteravhandling</0> eller <1>kandidatavhandling</1>.",
  "CREATING_ANOTHER_SALARY": "Skapa en annan lön",
  "SOURCE_OF_SALARY": "Lönekälla",
  "FROM_WHICH_ORGANIZATION_AND_OR_PROJECT_DO_YOU_GET": "Från vilken organisation och/eller projekt får du din lön från?",
  "DURATION_OF_SALARY": "Lönens varaktighet",
  "WHEN_DOES_THIS_SALARY_BEGIN_AND_END_IF_THERE_IS_NO": "När börjar och slutar denna lön? Gör en uppskattning om det inte finns något tydligt datum.",
  "SAVE_SALARY": "Spara lön",
  "DUE_DATE": "Förfallodatum",
  "SEND_REMINDER_EMAILS_AT": "Skicka påminnelsemail",
  "SAVE_REMINDER": "Spara påminnelse",
  "LOG_IN_OR_SIGN_UP_TO_ACCEPT_INVITATION": "Logga in eller registrera dig för att acceptera inbjudan.",
  "LOG_IN_WITH_UNIVERSITY_OF_HELSINKI_ACCOUNT": "Logga in med Helsingfors universitets konto",
  "I_DON_T_HAVE_A_UNIVERSITY_OF_HELSINKI_ACCOUNT": "Jag har inget konto vid Helsingfors universitet",
  "LOG_IN_WITH_AN_EXISTING_THESSA_ACCOUNT": "Logga in med ett befintligt Thessa-konto.",
  "LOG_IN_OR_SIGN_UP_FOR_A_THESSA_ACCOUNT_TO": "Logga in eller skapa ett Thessa-konto för att acceptera inbjudan.",
  "SIGN_UP_FOR_A_NEW_THESSA_ACCOUNT": "Skapa ett nytt Thessa-konto",
  "ERROR_LOGIN_OR_SIGN_UP": "FEL - logga in eller skapa ett konto!",
  "INVITATION_HAS_EXPIRED_AT": "Inbjudan har gått ut {{date}}. <0>Gå till Thessas förstasida</0>",
  "YOU_HAVE_BEEN_INVITED_TO_BECOME": "Du har blivit inbjuden att bli <0>{{roles}} för {{inviter}}</0> i Thessa.",
  "ACCEPT_INVITATION": "Acceptera inbjudan",
  "THERE_WAS_AN_ERROR_IN_DECLINING_THE_INVITATION": "<0>Det gick inte att tacka nej till inbjudan.</0> Försök igen. Vänligen <1>meddela oss</1> om problemet inte försvinner.",
  "SIGN_UP_FOR_A_NEW_THESSA_ACCOUNT_TO": "Skapa ett nytt Thessa-konto för att acceptera inbjudan.",
  "SIGN_UP": "Bli Medlem",
  "ERROR_IN_SIGN_UP": "Fel vid registrering.",
  "LOG_IN_FAILED_TRY_AGAIN": "Inloggningen misslyckades. Försök igen.",
  "ROLES": "Roller",
  "DOES_NOT_HAVE_ANY_ROLES_ASSIGNED": "<0>{{name}} har inga roller tilldelade.</0> Alla måste ha minst en roll.",
  "THERE_WAS_AN_ERROR_IN_UPDATING_ROLES": "<0>Det uppstod ett fel när rollerna skulle uppdateras.</0> Försök igen. Vänligen <1>meddela oss</1> om problemet inte försvinner.",
  "ONLY_ONE_PERSON_CAN_BE_THE_COORDINATING": "Endast en person kan vara den samordnande akademikern.",
  "ONLY_ONE_PERSON_CAN_BE_THE_MAIN_SUPERVISOR": "Endast en person kan vara huvudhandledare.",
  "ONE_PERSON_SHOULD_BE_SELECTED_AS_THE_COORDINATING": "En person bör väljas som samordnande akademiker.",
  "ONE_PERSON_SHOULD_BE_SELECTED_AS_THE_MAIN": "En person bör väljas till huvudhandledare.",
  "MYSELF": "Jag själv",
  "FILTERS": "Filter",
  "CHOOSE_A_DOCTORAL_PROGRAMME": "Välj ett doktorandprogram",
  "LOADING_INACTIVE_USER": "Laddar inaktiv användare...",
  "NO_STUDENT_NUMBER": "Inget studentnummer",
  "SIGNED_UP": "Anmäld",
  "NO_SUPERVISORS": "Inga handledare",
  "SUPERVISOR_INVITATIONS": "Handledareinbjudningar",
  "ACTIVATE": "Aktivera",
  "SEND_EMAIL": "Skicka epost",
  "NO_EMAIL_ADDRESS": "Ingen e-postadress",
  "THERE_WAS_AN_ERROR_IN_ACTIVATING_THE_USER_ACCOUNT": "<0>Det uppstod ett fel när användarkontot skulle aktiveras.</0> Vänligen <1>Försök igen</1>. Vänligen <2>meddela oss</2> om problemet inte försvinner.",
  "SELECT_DOCTORAL_PROGRAMME": "Välj doktorandprogram",
  "NO_DOCTORAL_PROGRAMME_SELECTED": "Inget doktorandprogram valt",
  "SELECT_DOCTORAL_PROGRAMMES": "Välj doktorandprogram",
  "ALL_DOCTORAL_PROGRAMMES": "Alla doktorandprogram",
  "NOT_VERIFIED": "obekräftat",
  "VERIFY_NOW": "Verifiera nu",
  "DOCTORAL_CANDIDATE_DOES_NOT_HAVE_ANY_SUPERVISORS": "Doktoranden har inga handledare.",
  "COUNT_THESIS_COMMITTEE_MEMBER": "{{count}} uppföjningsgruppmedlem",
  "COUNT_THESIS_COMMITTEE_MEMBER_other": "{{count}} uppföjningsgruppmedlemmar",
  "COUNT_SUPERVISOR_RELATIONSHIP": "{{count}} handledningsförhållande",
  "COUNT_SUPERVISOR_RELATIONSHIP_other": "{{count}} handledningsförhållanden",
  "COUNT_PUBLICATION_IN_PROGRESS": "{{count}} publicering pågår",
  "COUNT_PUBLICATION_IN_PROGRESS_other": "{{count}} publiceringar pågår",
  "COUNT_PUBLICATION_PUBLISHED": "{{count}} publikation publicerad",
  "COUNT_PUBLICATION_PUBLISHED_other": "{{count}} publikationer publicerade",
  "MAIN_SUPERVISOR": "Huvudhandledare",
  "MAIN_SUPERVISOR_other": "Huvudhandledare",
  "COORDINATING_ACADEMIC": "Koordinerande akademiker",
  "COORDINATING_ACADEMIC_other": "Samordnande akademiker",
  "COUNT_DOCTORAL_CANDIDATE": "{{count}} doktorand hittades",
  "COUNT_DOCTORAL_CANDIDATE_other": "{{count}} doktorander hittade",
  "ONLY_SHOW_DOCTORAL_CANDIDATES_WITHOUT_ACCOUNT": "Visa endast doktorander utan konto",
  "ONLY_SHOW_DOCTORAL_CANDIDATES_WITHOUT_SUPERVISOR": "Visa endast doktorander utan handledare",
  "COUNT_DOCTORAL_CANDIDATE_S_REPORT": "{{count}} doktoranders doktorandrapport är sen ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_S_REPORT_other": "{{count}} doktoranders rapport är försenad ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_S_LATEST": "{{count}} doktorands senaste rapport är inte ännu färdig ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_S_LATEST_other": "{{count}} doktoranders senaste rapport är inte ännu färdig ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_IS_ON_TIME": "{{count}} doktorand är i tid ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_IS_ON_TIME_other": "{{count}} doktorander är i tid ({{percentage}}%)",
  "COUNT_ITEM_IN_QUEUE": "{{count}} objekt i kö",
  "COUNT_ITEM_IN_QUEUE_other": "{{count}} objekt i kö",
  "OLDEST_ITEM_FIRST": "äldsta objektet först",
  "ISPROCEEDING": "pågår",
  "IS_THE_ANNUAL_REPORT_PROCEEDING_AS_PLANNED": "Går årsrapporten som planerat",
  "CONCLUSIONCOMMENT": "slutsatsHur",
  "COMMENT_ABOUT_THE_PROGRESS_OF_THE": "Kommentar om framstegen i avhandlingsarbetet skriven av huvudhandledare",
  "PROGRESS_FEEDBACK_BY": "Framstegsfeedback av",
  "IS_THE_DISSERTATION_WORK_PROCEEDING_AS_PLANNED": "Fortlöper avhandlingsarbetet som planerat?",
  "PROGRESS_FEEDBACK_IS_MISSING": "Framstegsfeedback saknas",
  "PROGRESS_FEEDBACK_SUBMITTED": "Framstegsfeedback har skickats",
  "GIVE_PROGRESS_FEEDBACK": "Ge feedback om framsteg",
  "THERE_WAS_A_PROBLEM_SUBMITTING_PROGRESS_FEEDBACK": "Det uppstod ett problem med att skicka in framstegsfeedback",
  "PROGRESS_FEEDBACK_SUBMITTED_REDIRECTING_YOU_BACK": "Framstegsfeedback har skickats. Omdirigerar dig tillbaka till <0>översiktssidan</0>",
  "PROGRESS_FEEDBACK": "Framstegsfeedback",
  "COMMENT1": "Kommentar",
  "OPTIONAL_COMMENT_ABOUT_THE_PROGRESS_OF_THE": "Valfri kommentar om framstegen i avhandlingsarbetet",
  "MOBILE": "Mobil",
  "DESKTOP": "Arbetsbord",
  "VIEW_ACCOUNT": "Visa konto",
  "EDIT_ROLES": "Redigera roller",
  "ENDING_ON_DATE": "Slutar den {{date}}",
  "INVITE_SUPERVISOR": "Bjud in handledare",
  "ADD_SUPERVISOR": "Lägg till handledare",
  "FAILED_TO_SEND_INVITE_PLEASE_TRY_AGAIN": "<0>Det gick inte att skicka inbjudan.</0> Försök igen eller <1>kontakta Thessa-stödet</1> för hjälp.",
  "SAVE_ROLES": "Spara roller",
  "ERROR_IN_SAVING_ROLES_TRY_AGAIN_OR": "Det gick inte att spara roller. Försök igen eller <0>kontakta Thessa-stödet</0>.",
  "INVITATION": "Inbjudan",
  "REMOVE_INVITATION": "Ta bort inbjudan",
  "YOU_HAVE_NO_ACTIVE_DOCTORAL_CANDIDATES_IN_THESSA": "Du har inga aktiva doktorander i Thessa just nu.",
  "NOT_IN_THESSA": "Inte i Thessa",
  "UPLOAD_AN_EXCEL_FILE_WITH_SUPERVISOR_RELATIONSHIPS": "Ladda upp en Excel-fil med handledningsförhållanden",
  "CHANGE_FILE": "Byt fil",
  "GO_BACK": "Gå tillbaka",
  "SAVE_SUPERVISOR_RELATIONSHIPS": "Spara handledningsförhållanden",
  "DOCTORAL_CANDIDATE_ROW": "Doktorandrad",
  "IGNORE": "Ignorera",
  "COORDINATING_ACADEMIC_EMAIL": "Den koordinerande akademikerns e-post",
  "MAIN_SUPERVISOR_EMAIL": "Huvudhandledarens e-post",
  "SUPERVISOR_EMAIL": "Handledarens e-post",
  "SUPERVISOR_RELATIONSHIPS": "Handledningsförhållanden",
  "UPLOAD_A_FILE_WITH_SUPERVISOR_RELATIONSHIPS": "Ladda upp en fil med handledningsförhållanden",
  "USE_THE_BUTTON_ABOVE_TO_UPLOAD_AN_EXCEL_FILE": "Använd knappen ovan för att ladda upp en Excel-fil. Filen ska ha <0>kolumner för studentnummer och e-postadresser för handledare</0>. Raderna ska representera enskilda doktorander.",
  "PICK_COLUMNS_AND_ROWS_TO_IMPORT": "Välj kolumner och rader du vill importera",
  "FOR_EACH_COLUMN_WITH_RELEVANT_DATA": "Välj rätt typ av kolumn från rullgardinsmenyn för varje kolumn med relevant information (studentnummer och handledarens e-postadresser). Markera resten av kolumnerna som ignorerade.",
  "THEN_SELECT_EACH_ROW_WITH_A_SUPERVISOR": "Välj sedan varje rad med en handledningsrelation och tryck på knappen Förhandsgranska.",
  "CHECK_THAT_THE_FOLLOWING_PREVIEW_IS_CORRECT": "Kontrollera att följande förhandsvisning är korrekt. Om förhandsgranskningen är korrekt trycker du på knappen Spara handledningsförhållanden för att starta importen. Om förhandsgranskningen är felaktig kan du ladda upp en ny Excel-fil eller trycka på gå tillbaka-knappen och ändra kolumn- och radval.",
  "IMPORT_SUCCESSFUL": "Importen lyckades",
  "IMPORT_FAILED_PLEASE_CONTACT_US_FOR_HELP": "<0>Import misslyckades.</0> <1>Kontakta oss</1> för hjälp.",
  "FILE_UPLOAD_WAS_NOT_SUCCESSFUL_PLEASE_TRY_AGAIN": "<0>Filöverföringen lyckades inte.</0> Försök igen.",
  "NOTES": "Anteckningar",
  "DONE1": "Gjort",
  "COURSE_TITLE": "Kursens namn",
  "SALARY_SOURCE": "Lönekälla",
  "GRANT_SOURCE": "Stipendiekälla",
  "STUDENT": "Studerande",
  "STATUS_OF_THE_PHD_PROJECT": "Doktorandprojektets status",
  "TIME_SPENT_ON_PHD": "Tid spenderad på doktorsexamen",
  "SOCIAL_IMPACT_AND_PUBLIC_OUTREACH": "Social påverkan och populariserande av vetenskap",
  "ATTACHMENTS": "Bilagor",
  "THREE_POSITIVE_THINGS": "Tre positiva saker",
  "THREE_NEGATIVE_THINGS": "Tre negativa saker",
  "CAREER_PLANNING": "Karriärplanering",
  "CONCLUSIONS": "Slutsatser",
  "OTHER_WORK": "Annat arbete",
  "WAIT_FOR_ONE_MINUTE_BEFORE_RESENDING": "Vänta en minut innan du sänder igen",
  "ACCOUNT_IS_CLOSED": "Kontot är stängt",
  "ACCOUNT_IS_ACTIVE": "Kontot är aktivt",
  "ACCOUNT_IS_INACTIVE": "Kontot är inaktivt",
  "ESTIMATED_STATUS_OF_THE_PHD_PROJECT": "Beräknad status för doktorandprojektet",
  "ESTIMATED_TIME_OF_PHD_DEFENCE": "Beräknad tid för disputation",
  "CAREER_PLANS_AFTER_YOUR_PHD": "Karriärplaner efter din doktorsexamen",
  "BACK_TO_OVERVIEW": "Tillbaka till översikten",
  "BACK_TO_REPORT": "Tillbaka till rapporten",
  "CHANGE_SUPERVISOR": "Byt handledare",
  "ADD_SUPERVISOR_FOR_NAME": "Lägg till handledare för {{name}}",
  "FAILED_TO_ADD_SUPERVISOR_PLEASE_TRY_AGAIN_OR": "<0>Det gick inte att lägga till handledare.</0> Försök igen eller <1>kontakta Thessa-supporten</1> för att få hjälp.",
  "FAILED_TO_INVITE_SUPERVISOR_PLEASE_TRY_AGAIN_OR": "<0>Det gick inte att bjuda in handledaren.</0> Försök igen eller <1>kontakta Thessa-stödet</1> för att få hjälp.",
  "INVITE_SUPERVISOR_FOR_NAME": "Bjud in handledare för {{name}}",
  "CHOOSE_LANGUAGE": "Välj språk",
  "PREPARATION_AND_ANALYSIS": "Förberedelse och analys",
  "WRITING": "Skrivande",
  "NAME_IS_NOT_YET_ON_THESSA_WHEN_THEY_SIGN_UP": "<0>{{name}} finns inte på Thessa ännu.</0> När de registrerar sig i Thessa kommer du att kunna se deras uppgifter.",
  "INVITE_THESIS_COMMITTEE": "Bjud in uppföljningsgrupp",
  "EDIT_THESIS_COMMITTEE": "Redigera uppföljningsgrupp",
  "YOUR_USER_ACCOUNT_NEEDS_TO_BE_ACTIVATED": "Ditt användarkonto måste aktiveras innan du kan skapa din första rapport. Innan ditt konto kan aktiveras måste du <0>bjuda in alla uppföljningsgruppmedlemmar</0> i Thessa.",
  "YOU_WILL_RECEIVE_AN_EMAIL_WHEN_YOUR_ACCOUNT": "Du kommer att få ett e-postmeddelande när ditt konto har aktiverats.",
  "ESTIMATE_MADE_ON": "Uppskattning gjord",
  "PHD_PROJECT_STATUS": "Framskridande",
  "TIME_SPENT_PERIOD": "Period",
  "EDIT_PRELIMINARY_TITLE_OF_DISSERTATION": "Redigera avhandlingens preliminära namn",
  "CREATE_PRELIMINARY_TITLE_OF_DISSERTATION": "Lägg till preliminär titel för avhandlingen",
  "SUBJECT_OR_STUDENT": "Ämne eller student",
  "APPLICATION_SENT": "Ansökan skickad",
  "PLANNING_TO_APPLY": "Planerar att ansöka",
  "CREDITS_one": "{{count}} studiepoäng",
  "CREDITS_other": "{{count}} studiepoäng",
  "CR": "{{count}} sp",
  "WHAT_WAS_DECIDED": "Vad bestämdes?",
  "ROLE_NAME": {
    "key": "value",
    "doctoral-candidate": "Doktorand",
    "coordinating-academic": "Ansvarsperson",
    "main-supervisor": "Huvudhandledare",
    "supervisor": "Handledare",
    "thesis-committee-member": "Uppföljningsgruppmedlem",
    "salary-system-foreman": "Förman",
    "coordinator": "Koordinator"
  },
  "CHECK_REPORT_STATUS": "Kontrollera rapportstatus.",
  "CHECK_SUPERVISION_PLAN_STATUS": "Kontrollera handledningsplanens status.",
  "SEARCH_BY": "Sök enligt {{keyword}}",
  "COMPLETE_THE_REPORT_FORM": "Fyll i rapporteringsblanketten och skicka in rapporten",
  "CONCLUSION_CAN_BE_WRITTER_AFTER": "Slutsatsen kan skrivas efter att rapporten har lämnats in.",
  "YES": "Ja",
  "NO": "Nej",
  "PLEASE_INVITE_TCMS_TO_THESSA": "Bjud in alla medlemmar i din uppföljningsgrupp till Thessa.",
  "COORDINATOR_TOOLS": {
    "key": "value",
    "MAIN_HEADING": "Verktyg",
    "HEADINGS": {
      "key": "value",
      "MANAGE_USERS": "Användare",
      "STATISTICS": "Statistik",
      "OTHER": "Övrig"
    },
    "TOOLS": {
      "key": "value",
      "LATEST_REPORTS": "Senaste rapporterna",
      "INCOMPLETE_REPORTS": "Ofullständiga rapporter",
      "ADMIN_REPORT": "Thessas adminrapport",
      "IMPORT_SUPERVISOR_RELATIONSHIPS": "Importera handledningsrelationer"
    },
    "DESCRIPTIONS": {
      "key": "value",
      "DOCTORAL_CANDIDATES": "Hantera doktorander",
      "USERS": "Hantera alla användare",
      "REPORT_CONCLUSIONS_OVERVIEW": "Se rapportslutsats för varje doktorand",
      "LATEST_REPORTS": "Se senaste rapportens status för varje doktorand",
      "INCOMPLETE_REPORTS": "Se doktorander och handledare med ofullständiga rapporter",
      "ADMIN_REPORT": "Exportera information om doktorander till CSV",
      "IMPORT_SUPERVISOR_RELATIONSHIPS": "Från Excel till Thessa"
    }
  },
  "SHOW_ALL_DOCTORAL_CANDIDATES": "Visa alla doktorander",
  "HIDE_INACTIVE_DOCTORAL_RESEARCHERS": "Dölj inaktiva doktorander",
  "DOCTORAL_RESEARCHER_OVERVIEW": "Doktorandens översikt",
  "LAST_COMPLETED_REPORT": "Senast färdig rapport",
  "FILTER_DOCTORAL_RESEARCHERS": "Filtrera doktorander",
  "SUPERVISOR_RELATIONSHIP_OVERVIEW": {
    "key": "value",
    "LINK": "https://studies.helsinki.fi/instruktioner/artikel/service-doktorander",
    "IF_MISSING": "Om en ansvarspersonen eller handledare saknas på listan,",
    "IF_MISSING_THEN_CONTACT": "kontakta din fakultets service för doktorander",
    "IF_CHANGED": "Om personerna har bytts,",
    "IF_CHANGED_THEN_CONTACT": "kontakta ditt doktorandprogram"
  },
  "THESIS_COMMITTEE_MEMBERS": {
    "key": "value",
    "SUPERVISORS_ARE_HANDLED_BY_OTHERS": "Handledarinformationen hanteras av din fakultets doktorandtjänster och ditt doktorandprograms planerare. Kontaktuppgifter finns i",
    "LINK": "https://studies.helsinki.fi/instruktioner/artikel/service-doktorander",
    "LINK_TITLE": "instruktioner för studerande",
    "INVITATION_HEADING": "Bjud in uppföjningsgruppmedlemmar",
    "INVITATION_HEADING_FOR_SUPERVISORS": "Bjud in handledare",
    "INVITATION_LABEL": "Uppföljningsgruppmedlems e-postadress",
    "INVITATION_LABEL_FOR_SUPERVISORS": "Handledares e-postadress"
  },
  "CONTACT": {
    "HEADING": "Kontakta oss",
    "PLANNING_OFFICER": "Forskarutbildningens planerare",
    "DOCTORAL_PROGRAMME_CONTACT_LINK_PREFIX": "Kontaktuppgifter för ditt doktorandprogram:",
    "DOCTORAL_PROGRAMME_CONTACT_LINK_URL": "https://studies.helsinki.fi/instruktioner/artikel/service-doktorander",
    "PLANNING_OFFICER_HELPS_WITH": "Planeringsansvariga hjälper till med sådana ämnen som:",
    "PLANNING_OFFICER_HELPS_WITH_ITEMS": [
      "Årliga rapporter",
      "Handledare och uppsatskommitté",
      "Tillsynsplaner",
      "Behöver jag använda Thessa?"
    ],
    "TECHNICAL_PROBLEMS": "Tekniska problem",
    "TECHNICAL_EMAIL_PREFIX": "Du kan skicka e-post om tekniska problem och fel i Thessa till",
    "TECHNICAL_EMAIL_LINK_TEXT": "Thessas utvecklare"
  },
  "DATE_RANGE_PICKER": {
    "INVALID_START_DATE": "Startdatumet är inte ett giltigt datum.",
    "INVALID_END_DATE": "Slutdatumet är inte ett giltigt datum.",
    "INVALID_END_IS_BEFORE_START": "Slutdatumet måste vara efter startdatumet."
  },
  "COORDINATOR_DOCTORAL_CANDIDATES": {
    "PLACEHOLDER": "Doktorandprogram eller studentnummer",
    "LABEL": "Sök på doktorandprogram eller studentnummer",
    "ACTION": "Sök"
  },
  "END_OF_SENTENCE_PERIOD": ".",
  "RESOURCES": {
    "INTRO_VIDEO": "LF7j_ITOYTw"
  },
  "MENU": "Meny",
  "FRONTPAGE": "Framsida",
  "OPEN_PROFILE_MENU": "Öppna profilmenyn",
  "CLOSE_PROFILE_MENU": "Stäng profilmenyn",
  "OPEN_MAIN_MENU": "Öppna huvudmenyn",
  "CLOSE_MAIN_MENU": "Stäng huvudmenyn",
  "CHANGE_LANGUAGE": "Ändra språk",
  "PROFILE_MENU": "Profilmenyn",
  "MAIN_MENU": "Huvudmenyn",
  "FOOTER_MENU": "Sidfot-meny",
  "DATE_NOT_SPECIFIED": "Datum ej specificat",
  "EMAIL_OR_USER_INPUT": {
    "HINT": "En giltig e-postadress krävs, eller välj en användare från förslag"
  },
  "FORM": {
    "REQUIRED_FIELD": "Obligatoriskt",
    "OPTIONAL_FIELD": "Valfritt",
    "VALID_EMAIL_ADDRESS_REQUIRED": "Ange en giltig e-postadress",
    "PASSWORD_REQUIREMENTS": "Minsta längd 10 tecken",
    "VALID": "Giltig",
    "INVALID": "Ogiltig",
    "OPTIONAL": "Valfri",
    "COMPLETE_FIELDS_TO_SAVE_FORM": "Observera att alla fält måste fyllas i för att kunna spara formuläret.",
    "COMPLETE_FIELDS_TO_SUBMIT_FORM": "Observera att alla fält måste fyllas i för att kunna skicka in formuläret."
  },
  "INVITATION_ROLE": {
    "COORDINATING_ACADEMIC": "en samordnande akademiker",
    "MAIN_SUPERVISOR": "en huvudhandledare",
    "SUPERVISOR": "en handledare",
    "THESIS_COMMITTEE_MEMBER": "en uppföljningsgruppmedlem"
  },
  "EDIT_PUBLICATION": "Redigera publikation",
  "EDIT_SALARY": "Redigera lön",
  "EDIT_GRANT": "Redigera stipendium",
  "EDIT_COURSE": "Redigera kurs",
  "EDIT_COURSE_WORKSHOP": "Redigera kurs/workshop",
  "EDIT_THESIS_INSTRUCTION": "Redigera instruktion för examensarbetet",
  "EDIT_TEACHING": "Redigera undervisning",
  "FOR_STUDENT": "för student",
  "CREATE_NEW_REMINDER": "Skapa ny påminnelse",
  "LOGO_OF_UNIVERSITY_OF_HELSINKI": "Helsingfors universitets logotyp",
  "NEW": "Ny",
  "CHANGED": "Ändrats",
  "INDICATOR": "indikator",
  "STATUS_IN_THE_MOST_RECENT": "Status i den senaste rapporten",
  "SHOW_DETAILS": "Visa detaljer",
  "HIDE_DETAILS": "Dölj detaljer",
  "ADD_PUBLICATION": "Lägg till publikation",
  "ADD_COURSE": "Lägg till kurs",
  "EDIT_NOTES": "Redigera <0>anteckningar</0>",
  "CANCEL_EDITING_NOTES": "Avbryt <0>redigering av anteckningar</0>",
  "SAVE_NOTES": "Spara <0>anteckningar</0>",
  "COURSE_IS_COMPLETED": "Kursen är slutförd",
  "SEND_MESSAGE": "Skicka meddelande",
  "REMOVE_COMMENT": "Ta bort kommentar",
  "REPORT_VIEW": {
    "ERROR": {
      "MISSING_REPORT": "Rapporten saknas.",
      "FAILED_TO_SAVE_REPORT": "<0>Det gick inte att spara rapporten.</0> Kontrollera rapportformuläret för fel och försök igen, eller <1>kontakta oss</1>.",
      "FAILED_TO_SUBMIT_REPORT": "<0>Det gick inte att skicka rapporten för granskning.</0> Kontrollera rapportformuläret för fel och försök igen, eller <1>kontakta oss</1>.",
      "FAILED_TO_DELETE_REPORT": "<0>Det gick inte att ta bort rapporten.</0> Försök igen eller <1>kontakta oss</1>."
    }
  },
  "PHD_PROJECT": {
    "ERROR": {
      "GENERAL_ERROR_HEADING": "Ett fel har inträffat i Thessa.",
      "YOU_CAN_RETRY_OR": "Du kan prova att uppdatera sidan men om det inte hjälper",
      "INCLUDE_THIS_TEXT": "Vänligen inkludera denna text i ditt e-postmeddelande",
      "CANNOT_INITIALIZE": "Det gick inte att ladda doktorandprojektdetaljer från servern."
    }
  },
  "CANCEL_INVITING_THESIS_COMMITTEE_MEMBER": "Avbryt inbjudande uppföljningsgruppmedlem",
  "INFORMATION": "Information",
  "COMMENT": "Kommentar",
  "WRITE_YOUR_COMMENT_HERE": "Skriv din kommentar här",
  "HIDE_DISCUSSION": "Dölj diskussion",
  "SHOW_DISCUSSION": "Visa diskussion",
  "VIEW_SUPERVISION_PLAN": "Visa handledningsplan",
  "ADD_COURSE_WORKSHOP": "Lägg till kurs/workshop",
  "ADD_THESIS_INSTRUCTION": "Lägg till instruktion för examensarbetet",
  "REPORT_STATUS": "Rapportstatus",
  "UNTITLED": "Namnlös",
  "UNTITLED_PUBLICATION": "Namnlös publikation",
  "UNTITLED_SALARY": "Namnlös lön",
  "UNTITLED_GRANT": "Namnlöst stipendium",
  "UNTITLED_COURSE": "Namnlös kurs",
  "UNTITLED_TEACHING": "Namnlös undervisning",
  "REMOVAL_CONFIRMATION": "Bekräfta borttagning",
  "THESSA_INTRODUCTION_VIDEO": "Thessa introduktionsvideo",
  "ACCESSIBILITY_STATEMENT": "Tillgänglighetsutlåtande",
  "INFORMATION_ON_THE_ACCESSIBILITY_OF_THE_WEBSITE": "Information om webbplatsens tillgänglighet",
  "LAST_UPDATED": "Senast uppdaterad",
  "LOGIN_FOR_EXTERNAL_USERS": "Inloggning utanför universitetet",
  "YOU_WILL_NOT_BE_ABLE_TO_ACCESS": "Du kommer inte att kunna komma åt Thessa när ditt konto är stängt.",
  "ASK_YOUR_DOCTORAL_PROGRAMME": "Kontajta ditt doktorandprogram för mer information.",
  "REMINDER": "Påminnelse",
  "BACK_TO_STATISTICS": "Tillbaka till statistik",
  "BACK_TO_USER_DETAILS": "Tillbaka till användardetaljer",
  "TOTAL_AMOUNT_OF_CREDITS": "Totalt antal studiepoäng",
  "FOR_EXAMPLE": "Till exempel",
  "DOWNLOAD": "Ladda ner",
  "PLEASE_SELECT_ONE_OF_THE_FOLLOWING_TYPES": "Vänligen välj en av följande typer",
  "PLEASE_SELECT_YOUR_ROLE_IN_THE_CONFERENCE": "Vänligen välj din roll i konferensen",
  "PLEASE_NOTE_YOU_HAVE_THE_OPTION_TO_SAVE": "Observera att du har möjlighet att spara dina framsteg när som helst, även om inte alla obligatoriska fält är ifyllda. Kom ihåg att återvända och fylla i all saknad information innan slutlig inlämning.",
  "DURATION_OF_TEACHING": "Undervisningens varaktighet",
  "CURRENT": "Aktuell",
  "UPCOMING": "Kommande",
  "SITEMAP": "Webbplatskarta",
  "UPON_LOGGING_IN": "När du loggar in kommer denna webbplatskarta att uppdateras automatiskt för att återspegla de sidor och funktioner som är tillgängliga för dig baserat på din roll i Thessa.",
  "OVERVIEW_OF_ALL_SITE_PAGES": "Översikt över alla webbplatsens sidor för enkel navigering och snabb åtkomst till innehåll.",
  "UPDATED": "Uppdaterad",
  "PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS": "Vänligen fyll i alla obligatoriska fält.",
  "PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS_AND_DATES": "Vänligen fyll i alla obligatoriska fält och datum.",
  "PLEASE_ENTER_VALID_DATES": "Vänligen ange giltiga datum.",
  "IS_MISSING": "saknas",
  "THE_VALUE_ENTERED_IS_INVALID": "Det angivna värdet är ogiltigt",
  "CLOSE": "Stäng",
  "CHOOSE": "Välj",
  "SUBMITTING_FORM_FAILED": "Det gick inte att skicka formuläret.",
  "TRANSCRIPT_OF_THESSA_INTRODUCTION_VIDEO": "Transkript av Thessa introduktionsvideo",
  "INTRODUCTION_TO_USING_THESSA_FOR_DOCTORAL": "Introduktion till att använda Thessa för doktorander",
  "CHOOSE_LANGUAGE_FROM_THE_LANGUAGE_NAVIGATION": "Välj språk från språknavigeringen",
  "AFTER_LOGGING_IN": "Efter att du har loggat in kommer du att landa på hemsidan som kallas \"Mitt doktorandprojekt\" sida, som består av avsnitt fö",
  "SUPERVISION_PLANS_ARE_CREATED_BY_YOUR_SUPERVISORS": "Handledningsplaner skapas av dina handledare. Du kommer att få ett e-postmeddelande när dina handledare har skapat en ny handledningsplan för dig. För att signera planen, klicka på länken i e-postmeddelandet, och klicka sedan på \"Acceptera planen\"-knappen på handledningsplansidan.",
  "PLANS_FOR_YOUR_PHD_PROJECT": "Planer för ditt doktorandprojekt",
  "PLANS_FOR_YOUR_PHD_PROJECT_ARE_CREATED_BY_YOU": "Planer för ditt doktorandprojekt skapas av dig. Dessa planer innehåller information om dina publikationer, konferenser, kurser och andra aktiviteter relaterade till ditt doktorandprojekt.",
  "REPORTS_ARE_CREATED_BY_YOU_AND_INCLUDE_INFORMATION": " Rapporter skapas av dig och innehåller information om dina framsteg i ditt doktorandprojekt. De kan sparas som utkast och skickas in när du är redo.",
  "YOUR_SUPERVISORS_ARE_ADDED_AUTOMATICALLY": "Dina handledare läggs till automatiskt. Du kan se vilka dina handledare är och deras kontaktinformation.",
  "YOU_CAN_SEE_INFORMATION_ABOUT_YOUR_THESIS_COMMITTEE": "Du kan se information om medlemmarna i din uppföljningsgrupp. Medlemmar i uppföljningsgruppen måste bjudas in manuellt genom att navigera till uppföljningsgruppens sida.",
  "DIGITAL_SERVICE_ACCESSIBILITY_STATUS": "Tillgänglighetsstatus för digital tjänst",
  "GENERAL_SECTIONS_AND_FEATURES_THAT_ARE_NOT_ACCESSIBLE": "Allmänna avsnitt och funktioner som inte är tillgängliga",
  "SERVICES_FOR_DOCTORAL_RESEARCHERS_THAT_ARE_NOT_ACCESSIBLE": "Tjänster för doktorander som inte är tillgängliga",
  "SERVICES_FOR_SUPERVISORS_THAT_ARE_NOT_ACCESSIBLE": "Tjänster för handledare som inte är tillgängliga",
  "SERVICES_FOR_COORDINATORS_THAT_ARE_NOT_ACCESSIBLE": "Tjänster för koordinatorer som inte är tillgängliga",
  "NOT_WITHIN_THE_SCOPE_OF_LEGISLATION": "Inte inom lagstiftningens omfattning",
  "ACCESSIBILITY_FEEDBACK": "Tillgänglighetsfeedback",
  "SUPERVISORY_AUTHORITY": "Tillsynsmyndighet",
  "CONTACT_INFORMATION_OF_THE_REGULATOR": "Kontaktinformation för tillsynsmyndigheten",
  "REGIONAL_STATE_ADMINISTRATIVE_AGENCY_FOR_SOUTHERN_FINLAND": "Regionförvaltningsverket för södra Finland",
  "ACCESSIBILITY_CONTROL_UNIT": "Tillgänglighetsenheten",
  "TEL_SWITCHBOARD": "Växel",
  "PERCEIVABLE": "Möjlig att uppfatta",
  "OPERABLE": "Hanterbar",
  "UNDERSTANDABLE": "Begriplig",
  "ROBUST": "Robust",
  "ACCESSIBILITY_OF_THESSA": "Tillgänglighet i Thessa",
  "THIS_ACCESSIBILITY_STATEMENT_APPLIES": "Detta tillgänglighetsutlåtande gäller <0>Thessa-tjänsten för Helsingfors universitet</0>",
  "THE_SERVICE_IS_SUBJECT_TO_THE_LAW": "Tjänsten omfattas av lagen om att tillhandahålla digitala tjänster, vilken kräver att offentliga nättjänster måste vara tillgängliga. Vi har själva bedömt tjänstens tillgänglighet.",
  "THE_ACCESSIBILITY_STATEMENT_HAS_BEEN_CREATED_ON": "Tillgänglighetsutlåtandet har skapats den {{ createdDate }} och senast uppdaterats den {{ lastUpdatedDate }}.",
  "INFO_AND_RELATIONSHIPS": "WCAG 1.3.1: Information och relationer",
  "ERROR_IDENTIFICATION": "CAG 3.3.1: Identifiering av fel",
  "ON_INPUT": "WCAG 3.2.2: Vid inmatning",
  "USE_OF_COLOR": "WCAG 1.4.1: Användning av färger",
  "CONTRAST_MINIMUM": "WCAG 1.4.3: Kontrast (minimum)",
  "PARSING": "WCAG 4.1.1: Parsing",
  "NAME_ROLE_VALUE": "WCAG 4.1.2: Namn, roll, värde",
  "STATUS_MESSAGES": "WCAG 4.1.3: Statusmeddelanden",
  "THE_THESSA_SERVICE_OF_THE_UNIVERSITY_OF_HELSINKI_MEETS": "Helsingfors universitets tjänst Thessa uppfyller de flesta tillgänglighetskraven på A- och AA-nivå (WCAG-2.1). De upptäckta bristerna i tillgängligheten beskrivs närmare nedan. Det strävas efter att åtgärda dessa brister i samband med underhållet av den digitala tjänsten. Läs mer om <0>tillgänglighet vid Helsingfors universitet.</0>",
  "DATA_TABLES_ARE_NOT_PROGRAMMATICALLY_DISPLAYED": "Information som presenteras i tabeller är inte automatiskt angiven i tabellformat",
  "THE_INFORMATION_ON_THE_CONFERENCES_AND_MOBILITY": "Informationen i delen konferens och mobilitet på sidan Mitt doktorandprojekt är inte automatiskt angiven i tabellformat",
  "FORMS_DO_NOT_SUFFICIENTLY_GUIDE_USERS_ON_INCORRECT": "Formulären ger inte användaren tillräcklig vägledning vid felaktig eller ofullständig inmatning av information",
  "THE_SUBMISSION_BUTTONS_IN_THE_FORMS_FOR_SUPERVISION": "Knapparna för att skicka formulären på sidorna för handledningsplanen och rapporter är ur bruk om formulären är ofullständigt eller felaktigt ifyllda",
  "THE_INFORMATION_ON_THE_MY_DOCTORAL_THESIS_PAGE": "Informationen på sidan Mina doktorander är inte automatiskt angiven i tabellformat",
  "A_FORM_DOES_NOT_DISPLAY_THE_SUBMISSION_BUTTON": "Formuläret visar inte knappen för att skicka in formuläret",
  "THE_SUBMISSION_BUTTON_FOR_THE_PROGRESS_FEEDBACK": "Knappen för att skicka in framstegsfeedback i samband med rapporten visas först i förhandsgranskningsläget, och är annars dold för användaren",
  "GRAPHIC_ELEMENTS_LACK_TEXT_ALTERNATIVES_AND_ARE_NOT": "Grafiska element saknar alternativ text och de är inte automatiskt angivna som bilder",
  "ON_THE_LIST_DOCTORAL_RESEARCHERS_ACCORDING_TO_REPORT": "På sidan Lista doktorander efter rapportstatus är vissa grafiska element bristfälligt automatiskt angivna som bilder och en del saknar alternativa texter",
  "TITLES_HAVE_NOT_BEEN_USED_TO_PARSE_CONTENT": "Rubriker har inte använts för att strukturera innehållet",
  "THE_TITLES_ON_THE_OVERVIEW_OF_CONCLUDING_STATEMENTS": "Rubriker har inte använts för att strukturera innehållet på sidan Rapport översikt över slutsatser.",
  "THE_DISTINCTIVENESS_OF_LINKS_FROM_OTHER_TEXT_AND_FOCUS": "Länkar urskiljs från annan text endast med hjälp av färger och fokusstilarna förlitar sig endast på färger",
  "IN_THE_COORDINATOR_SERVICES_THE_DISTINCTIVENESS": "På flera sidor i koordinatortjänsterna kan urskiljningen av länkar från annan text samt interaktiva elements fokusstilar förlita sig endast på förändringar i färg",
  "THE_CONTRAST_RATIO_OF_TEXT_ELEMENTS_COMPARED": "Textelementens kontrastvärde är för lågt i förhållande till bakgrunden",
  "THE_CONTRAST_RATIO_OF_TEXT_ELEMENTS_ON_THE": "Textelementen på sidan Rapport översikt över slutsatser har för lågt kontrastvärde i förhållande till bakgrunden",
  "THE_SUBMISSION_BUTTON_IN_THE_FORM_FOR_ADDING_SUPERVISORS": "Knappen för att skicka formuläret för att lägga till en handledare på sidan Doktorander är ur bruk om formuläret är ofullständigt eller felaktigt ifyllt",
  "THERE_ARE_DEFICIENCIES_IN_THE_PROGRAMMATIC": "Det finns brister i webbplatsens programstruktu",
  "THE_USER_INFORMATION_PAGE_CONTAINS_BUTTONS_THAT": "Sidan Användare har knappar som i strid med deras specifikationer är nästlade, vilket gör det svårt för användare som använder tangentbords- och skärmläsare att använda dem",
  "BUTTONS_HAVE_NO_VISIBLE_OR_PROGRAMMATIC_TEXT": "Knapparna saknar både synlig och programmerad text",
  "THE_SUPERVISOR_FORM_ON_THE_DOCTORAL_RESEARCHERS_PAGE": "Formuläret Handledare på sidan Doktorander har knappar som saknar både synlig och programmerad text",
  "SCREEN_READERS_DO_NOT_AUTOMATICALLY": "Skärmläsarprogram presenterar inte automatiskt meddelanden om status",
  "ON_THE_DOCTORAL_RESEARCHERS_AND_LIST_THE_REVIEWERS": "Sidorna Doktorander och Lista granskare av ej färdigställda rapporter presenterar inte automatiskt meddelanden om status",
  "NOT_COVERED_BY_LEGISLATION": "Innehåll som inte omfattas av lagstiftningens krav är",
  "CONTENT_PUBLISHED_BY_USERS_OR_OTHER_THIRD_PARTIES": "Innehåll som publiceras i tjänsten av användare eller andra utomstående aktörer, och som inte produceras, finansieras eller övervakas av tjänsteleverantören",
  "CONTENT_PUBLISHED_BY_USERS_THAT_CANNOT": "Det kan förekomma innehåll på webbplatsen som publicerats av användarna och som inte kan ändras så att de uppfyller tillgänglighetskraven. Innehåll som publicerats av användarna har inte heller programmerats att använda samma språk som innehållet.",
  "DID_YOU_NOTICE_AN_ACCESSIBILITY_ISSUE_IN": "Har du upptäckt bristande tillgänglighet i vår digitala tjänst? Meddela oss per e-post så gör vi vårt bästa för att åtgärda bristen.",
  "IF_YOU_NOTICE_ACCESSIBILITY_ISSUES_ON": "Om du upptäcker tillgänglighetsproblem på webbplatsen, ge respons till oss som är webbansvariga. Det kan dröja 14 dagar innan du får ett svar. Om du inte är nöjd med det svar du har fått eller inte får något svar alls inom två veckor <0>kan du lämna in en anmälan om bristande tillgänglighet till Regionförvaltningsverket i Södra Finland</0>. På regionförvaltningsverkets webbplats beskrivs i detalj hur du kan lämna in en anmälan och hur ärendet behandlas.",
  "PAGINATION": "Sidindelning",
  "GO_TO_PAGE": "Gå till sida",
  "AMOUNT_OF_ITEMS_PER_PAGE": "Antal objekt per sida",
  "THERE_WAS_AN_ERROR_IN_LOADING_SUPERVISION_PLANS": "<0>Det uppstod ett fel vid inläsningen av handledningsplaner.</0> Vänligen <1>meddela oss</1> om problemet inte försvinner.",
  "PHD_PROJECT_LIST": {
    "ERROR_DELETE_FAILED": "Raderingen misslyckades."
  },
  "THESIS_COMMITTEE": {
    "TITLE": "Uppföljningsgrupp",
    "MEETINGS": "Möten",
    "CHOOSE_FIRST_MEETING_DATE": "Välj datum för första uppföljningsgruppens möte",
    "NO_FIRST_MEETING_DATE_YET": "Datum för första möte är ännu inte valt",
    "CHOOSE_DATE": "Välj datum",
    "CHANGE_DATE": "Ändra datum",
    "MEETING": "Uppföljningsgruppens möte",
    "FIRST_MEETING": "Första uppföljningsgruppens möte",
    "MEETING_DATE": "Mötesdatum",
    "MEETING_DATE_IN_PAST": "Mötesdatum ligger i det förflutna",
    "ERROR_CHOOSING_DATE": "Ett fel uppstod när mötet skulle sparas. Försök igen.",
    "ERROR_MISSING_DATE": "Välj mötesdatum innan du sparar.",
    "REPORT_FOR_MEETING": "Rapport för mötet",
    "REPORT_FOR_MEETING_READY": "<0>En rapport</0> är redo att diskuteras på mötet.",
    "REPORT_FOR_MEETING_MISSING": "<0>Rapport saknas.</0> Lämna en rapport mellan <0>{{ startDate }}–{{ endDate }}</0> för att den ska diskuteras i detta möte."
  },
  "SUPERVISION_PLAN_TITLE_NO_END_DATE": "Giltig från {{startDate}}",
  "SUPERVISION_PLAN_HEAD_TITLE": "Handledningsplan {{startDate}} - {{endDate}}",
  "SUPERVISION_PLAN_HEAD_TITLE_NO_END_DATE": "Handledningsplan från {{startDate}}",
  "VALIDITY": "Giltighet",
  "COPY_DECISIONS_TO_NEW_PLAN": "Kopiera beslut till ny plan",
  "COPY_DECISIONS_FROM_A_PREVIOUS_PLAN": "Kopiera beslut från en tidigare plan",
  "PREVIEW_OF_THE_SUPEVISION_PLAN_FOR": "Förhändsgranskning av handledningsplanen för {{name}}",
  "SELECT_A_PREVIOUS_SUPERVISION_PLAN_TO_COPY_FROM": "Välj en tidigare handledningsplan att kopiera från",
  "PLEASE_NOTE_THAT_SUPERVISION_PLANS_MAY_CONTAIN": "Observera att handledningsplaner kan innehålla känslig information, såsom forskningsdetaljer och personuppgifter.",
  "CHAPTER": "Period",
  "CHAPTERS": "Perioder",
  "CHAPTERS_AND_MILESTONES": "Perioder och Milstolpar",
  "CURRENT_CHAPTER": "Aktuell period",
  "MILESTONE": "Milstolpe",
  "MILESTONES": "Milstolpar",
  "PERSONAL_MILESTONE": "Personlig milstolpe",
  "PERSONAL_MILESTONES": "Personliga milstolpar",
  "COMMON_MILESTONE": "Gemensam milstolpe",
  "COMMON_MILESTONES": "Gemensamma milstolpar",
  "ADD_PERSONAL_MILESTONE": "Lägg till personlig milstolpe",
  "ADD_COMMON_MILESTONE": "Lägg till gemensam milstolpe",
  "STARTING": "Startar",
  "STARTED": "Startade",
  "PLANNED": "Suunniteltu",
  "ESTIMATED": "Uppskattas",
  "STARTED_FROM_GRANTING_STUDY_RIGHT": "Startade <0>{{ date }}</0> från beviljandet av studierätt",
  "STARTED_AT_THE_THESIS_COMMITTEE_MEETING_HELD_ON": "Startade <0>{{ date }}</0> vid uppföljningsgruppen som hålls den",
  "BEGIN_AT_THE_THESIS_COMMITTEE_MEETING_HELD_ON": "Börjar vid uppföljningsgruppen som hålls den <0>{{ date }}</0>",
  "BEGIN_AT_THE_MEETING_ESTIMATED_TO_BE_HELD_ON": "Börjar vid uppföljningsgruppen hållas den <0>{{ date }}</0>",
  "EACH_CHAPTER_REPRESENTS_THE_TIME_PERIOD": "Varje kapitel representerar tidsperioden mellan två möte med uppföljningsgruppen.",
  "OVERVIEW": "Översikt",
  "NO_MILESTONES_IN_THE_PLAN_FOR_THE_CHAPTER_YET": "Inga milstolpar i planen för perioden ännu.",
  "VIEW_CHAPTERS": "Visa perioder",
  "EDIT_MILESTONES": "Redigera milstolpar",
  "CUSTOM_MILESTONES_CREATED_BY_THE_USER": "Anpassade milstolpar skapade av användaren. Du kan lägga till så många personliga milstolpar du vill och tilldela dem till vilket kapitel som helst.",
  "A_PREDEFINED_SET_OF_MILESTONES_THAT_MUST_BE_ASSIGNED": "En fördefinierad uppsättning milstolpar som måste tilldelas ett kapitel. Vanliga milstolpar är grupperade i följande kategorier",
  "STUDY_RIGHT_GRANTED": "Studierätt beviljad",
  "ADD_ITEM": "Lägg till objekt",
  "MILESTONE_DESCRIPTION": "Milstolpens beskrivning",
  "MOVE": "Flytta",
  "OPEN_CONTEXT_MENU": "Öppna kontextmenyn"
}
